import { media } from '@/Shared/theme';
import { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const StyledNotification = styled.button`
	align-items: center;
	background: #efefef;
	border-radius: 6px;
	border: none;
	color: var(--label-text);
	cursor: pointer;
	display: flex;
	margin-bottom: var(--spacing-8);
	padding: var(--spacing-1);
	transition: background-color 0.3s, color 0.3s;

	${media.condensed} {
		padding: var(--spacing-1) var(--spacing-4) var(--spacing-1) var(--spacing-1);
	}

	&:hover,
	&:focus-visible {
		background: var(--elevate-primary);
		color: var(--neutral-50);
	}

	&:has(.HW_badge.HW_visible) {
		background: var(--neutral-50);
		color: var(--elevate-primary);
		box-shadow: inset 0 0 0 2px var(--elevate-primary);

		&:hover,
		&:focus-visible {
			color: var(--neutral-50);
			background: var(--elevate-primary);
		}
	}

	.update-notification-label {
		display: none;
		${media.condensed} {
			display: inline;
		}
	}

	.HW_badge.HW_softHidden,
	.HW_badge {
		background: var(--elevate-secondary);
	}
`;

const GlobalNotificationStyle = createGlobalStyle`
	.HW_frame_cont.HW_bottom.HW_visible {
		margin-left: var(--spacing-6);
	}
`;

export default function AppUpdateNotification() {
	useEffect(() => {
		const config = {
			selector: '#app-update-indicator', // CSS selector where to inject the badge
			account: 'JnO4py',
			trigger: '.update-notification',
		};

		(window as any).Headway?.init(config);
	}, []);

	return (
		<>
			<GlobalNotificationStyle />
			<StyledNotification className="update-notification">
				<span id="app-update-indicator" />
				<span className="update-notification-label">Latest Updates</span>
			</StyledNotification>
		</>
	);
}
