import React, { useState } from 'react';
import styled from 'styled-components';

import { CloseIcon, TrashCanIcon } from '@/icons/index';
import { StyledH5 } from '@/Shared/Typography/typography';

const StyledCourseList = styled.div`
	position: absolute;
	background-color: var(--panel-color);
	bottom: 0;
	box-shadow: -4px 0px 8px rgb(0 0 0 / 15%);
	display: flex;
	flex-direction: column;
	opacity: 1;
	padding: var(--spacing-4);
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity 0.3s;
	width: 350px;
	z-index: 1;

	.selection-container {
		background-color: var(--neutral-50);
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}

	span {
		background-color: var(--neutral-50);
		display: inline-block;
		padding: var(--spacing-2) var(--spacing-4);
	}

	.course-btn {
		background-color: var(--neutral-50);
		border-radius: 0;
		border: none;
		padding: var(--spacing-2) var(--spacing-4);
		text-align: left;
		&:hover {
			background-color: var(--grey-focus-bg);
		}
		&:focus {
			background-color: var(--grey-active-bg);
			outline: none;
		}
	}
`;

const StyledListItem = styled.div`
	display: flex;
	align-items: center;

	div {
		padding: var(--spacing-4) var(--spacing-4);
		margin-right: auto;
	}

	.remove-btn {
		margin-right: var(--spacing-4);
	}
`;

const StyledCloseIcon = styled(CloseIcon as any)`
	background-color: var(--tab-group-color);
	left: -48px;
	z-index: 1;
	right: auto;
`;

interface MergeCourseMapListProps {
	selectedNode: any;
	isAddingCourse: boolean;
	updateTreeData(obj: any): void;
	isParentMenuActive(obj: any): void;
}

const MergeCourseMapList = ({
	selectedNode,
	updateTreeData,
	isAddingCourse,
	isParentMenuActive,
}: MergeCourseMapListProps) => {
	const [resultQty] = useState<number>(1);
	const rootNode = (node: any) => {
		const ancestors = node.ancestors();
		const path = ancestors.length - 1;
		return ancestors[`${path}`];
	};

	const chooseCourse = (node: any) => {
		const { id, name, tracker } = node.data;
		if (selectedNode.data.suppParent === undefined) {
			selectedNode.data.suppParent = [];
		}
		selectedNode.data.suppParent.push({
			id,
			name,
			tracker,
		});

		const root = rootNode(node);
		updateTreeData({ ...root.data });
	};

	const removeCourse = (node: any) => {
		selectedNode.data.suppParent = selectedNode.data.suppParent.filter(
			(parent: any) => parent.tracker !== node.tracker
		);

		const root = rootNode(selectedNode);
		updateTreeData({ ...root.data });
	};

	const hasMultiChildren = selectedNode.ancestors().filter((a: any) => {
		if (
			a.children?.length >= 2 &&
			(a.data.discipline === selectedNode.data.discipline || a.data.name.includes('Spacer'))
		) {
			return a;
		}
		return false;
	});

	let theList: any = [];
	theList = hasMultiChildren
		.map((a: any) => a.descendants())
		.flat()
		.filter(
			(a: any) =>
				(a.depth === selectedNode.depth || a.depth === selectedNode.depth - 1) &&
				a.id !== selectedNode.parent.id &&
				a.data.tracker !== selectedNode.data.tracker
		)
		.sort((a: any, b: any) =>
			a.data.name < b.data.name ? -1 : Number(a.data.name > b.data.name)
		);

	const courseOptions = Array.from(new Set(theList.map((a: any) => a.id))).map((id) =>
		theList.find((a: any) => a.id === id)
	);

	const existingMerges = selectedNode.data?.suppParent ?? [];

	return (
		<StyledCourseList id="course-menu">
			<StyledCloseIcon passedEvent={() => isParentMenuActive(false)} />
			{isAddingCourse && (
				<>
					<StyledH5 mb="16px">Available Merges</StyledH5>
					<div className="selection-container">
						{courseOptions.map((node: any) => (
							<button
								className="course-btn"
								type="button"
								onClick={() => chooseCourse(node)}
								key={`${node.data.tracker}-merge`}>
								{node.data.name}
							</button>
						))}
						{courseOptions.length === 0 && <span>No Available Merges...</span>}
						{resultQty === 0 && <span>No Matches...</span>}
					</div>
				</>
			)}

			{!isAddingCourse && (
				<>
					<StyledH5 mb="16px">Existing Merges</StyledH5>
					<div className="selection-container">
						{existingMerges.map((node: any) => (
							<StyledListItem key={`merge-${node.tracker}`}>
								<div>{node.name}</div>
								<TrashCanIcon
									className="remove-btn"
									passedEvent={() => removeCourse(node)}
								/>
							</StyledListItem>
						))}
						{existingMerges.length === 0 && <span>No Existing Merges...</span>}
					</div>
				</>
			)}
		</StyledCourseList>
	);
};

export default MergeCourseMapList;
