import { useTable, useSortBy } from 'react-table';
import styled from 'styled-components';

import { NoDataIcon } from '@/icons/index';

interface TableDataProp {
	cellData?: any;
	columnData?: any;
	key?: any;
	sortable?: boolean;
	centered?: boolean;
	fixedWidth?: string;
}

const StyledTableScroller = styled.div`
	height: calc(100% - 100px);
	overflow: auto;
	padding-bottom: 50px;

	th {
		background-color: var(--neutral-50);
		position: sticky;
		top: 0;
	}

	tr {
		transition: background-color ease 0.3s;
	}

	.active-row {
		background-color: var(--active-row-color);
	}
`;

const StyledTableElements = styled.table`
	border-spacing: 0;
	color: var(--label-text);
	width: 100%;
`;

const StyledCell = styled.td<TableDataProp>`
	box-shadow: inset 0px -1px 0px rgba(0, 15, 25, 0.1);
	padding: var(--spacing-6) var(--spacing-3);
	text-align: ${(props) => (props.columnData?.alignment ? props.columnData?.alignment : 'left')};

	div {
		margin: auto;
	}

	&.assignedCourseColumn:not(:empty) {
		display: flex;
		flex-direction: column;
		max-height: 200px;
		min-height: 85px;
		overflow: auto;

		div {
			margin: auto 0;
		}
	}
`;

const StyledTableHead = styled.th<TableDataProp>`
	box-shadow: inset 0px -1px 0px rgba(0, 15, 25, 0.1);
	font-family: var(--font-bold);
	padding: ${(props) => {
		if (props.columnData?.padding) {
			return props.columnData?.padding;
		}
		return '0 var(--spacing-3) var(--spacing-2) var(--spacing-3)';
	}};
	text-align: ${(props) => (props.columnData?.alignment ? props.columnData?.alignment : 'left')};
	width: ${(props) => (props.columnData?.fixedWidth ? props.columnData?.fixedWidth : 'auto')};
`;

interface TableProps {
	data: any;
	columns: any;
}

const Table = ({ data, columns }: TableProps) => {
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns,
			data,
		},
		useSortBy
	);

	return (
		<>
			{data.length === 0 && <NoDataIcon />}

			{data && data.length !== 0 && (
				<StyledTableScroller>
					<StyledTableElements {...getTableProps()}>
						<thead>
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<StyledTableHead
											columnData={column}
											{...column.getHeaderProps()}
											sortable={false}>
											{column.render('Header')}
										</StyledTableHead>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{rows.map((row, i) => {
								const lastRowClass = i === rows.length - 1 ? 'last-row' : '';
								const rowId = (row.original as Record<string, unknown>).idColumn;

								prepareRow(row);
								return (
									<tr
										{...row.getRowProps()}
										id={`row-${rowId}`}
										className={lastRowClass}>
										{row.cells.map((cell: any) => {
											return (
												<StyledCell
													className={cell.column.id}
													cellData={cell}
													{...cell.getCellProps()}>
													{cell.render('Cell', {
														rowID: cell.row.original.id,
														rowData: cell.row.original,
													})}
												</StyledCell>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</StyledTableElements>
				</StyledTableScroller>
			)}
		</>
	);
};

export default Table;
