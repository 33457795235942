import styled from 'styled-components';
import { media } from '@/Shared/theme';

const StyledIcon = styled.div`
	align-items: center;
	color: var(--label-text);
	cursor: pointer;
	display: flex;

	svg {
		transition: color 0.15s ease-in;
	}

	span {
		display: none;
		margin-left: var(--spacing-2);
		${media.condensed} {
			display: inline;
		}
	}
`;

const PublicViewIcon = () => (
	<StyledIcon>
		<svg
			width="35"
			fill="currentColor"
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M452 157.9c4.1 0 7.5-3.4 7.5-7.5v-52c0-14.9-11.6-27-26.3-27.8-89-4.5-136.8-41-157.4-62.2C270.6 3 263.6 0 256 0s-14.6 3-19.8 8.4c-20.6 21.3-68.4 57.7-157.4 62.2-14.7.7-26.3 13-26.3 27.7v209.1c0 60.3 32.4 116.4 84.6 146.5l84.8 49a68 68 0 0068.2 0l84.8-49a169.6 169.6 0 0084.6-146.5v-122a7.5 7.5 0 00-15 0v122c0 55-29.6 106-77 133.5l-85 49a53.2 53.2 0 01-53 0l-84.9-49a154.6 154.6 0 01-77-133.5v-209c0-6.9 5.2-12.5 12-12.8 94-4.8 145.2-44 167.3-66.8 2.4-2.4 5.6-3.8 9.1-3.8s6.7 1.4 9 3.8c22.2 22.9 73.3 62 167.4 66.8 6.8.3 12 6 12 12.7v52c0 4.2 3.4 7.6 7.6 7.6z" />
			<path d="M100.4 254.9a7.5 7.5 0 00-7.5 7.5v45c0 45.9 24.7 88.6 64.4 111.5l84.9 49a27.8 27.8 0 0027.7 0l84.8-49c39.7-22.9 64.4-65.6 64.4-111.5V126c0-8.9-6.6-16.4-15.4-17.5-67.3-8.4-111.5-34-136.8-54-6.4-5-15.4-5-21.8 0-25.3 20-69.5 45.6-136.8 54-8.7 1.1-15.4 8.6-15.4 17.5v101.4a7.5 7.5 0 0015 0V126c0-1.3 1-2.4 2.3-2.6 70.7-8.9 117.4-36 144.2-57 1-.8 2.3-.8 3.2 0 26.8 21 73.5 48.1 144.2 57 1.3.2 2.3 1.3 2.3 2.6v181.4a114 114 0 01-56.9 98.5l-84.8 49a12.9 12.9 0 01-12.7 0l-84.9-49a114 114 0 01-56.9-98.5v-45c0-4.2-3.3-7.5-7.5-7.5z" />
			<path d="M175.2 241.4c-9.7 0-17.6 8-17.6 17.6v111.1c0 9.7 7.9 17.6 17.6 17.6h161.6c9.7 0 17.6-7.9 17.6-17.6V316a7.5 7.5 0 00-15 0v54.2c0 1.5-1.2 2.6-2.6 2.6H175.2a2.6 2.6 0 01-2.6-2.6v-111c0-1.5 1.2-2.7 2.6-2.7h161.6c1.4 0 2.6 1.2 2.6 2.6v22a7.5 7.5 0 0015 0v-22c0-8.5-6-15.6-14.1-17.2v-55.5a84.4 84.4 0 00-168.6 0v20.2c0 4.1 3.4 7.5 7.5 7.5h32.4c4.1 0 7.5-3.4 7.5-7.5v-20.2a37 37 0 0173.8 0v55.1H175.2zm80.8-107a52 52 0 00-52 51.9V199h-17.3v-12.7a69.3 69.3 0 01138.6 0v55.1h-17.4v-55.1a52 52 0 00-51.9-52z" />
			<path d="M256 355.4c4.1 0 7.5-3.3 7.5-7.5v-26a24.7 24.7 0 10-15 0v26c0 4.2 3.4 7.5 7.5 7.5zm0-66.7a9.7 9.7 0 110 19.4 9.7 9.7 0 010-19.4z" />
		</svg>
		<span>Public View</span>
	</StyledIcon>
);

export default PublicViewIcon;
