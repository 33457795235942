import React from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';

import { StyledH4, StyledP } from '@/Shared/Typography/typography';
import {
	StyledFeatherBackgroundTop,
	StyledFeatherBackgroundBottom,
} from '@/Pages/ResetPassword/ResetPasswordStyles';
import { StyledButton } from '@/Shared/StyledElements';

export const StyledErrorBounds = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	overflow: hidden;
	position: relative;

	p {
		max-width: 600px;
	}
`;

interface ErrorBoundaryState {
	hasError: boolean;
}

export class ErrorBoundary extends React.Component<any, ErrorBoundaryState> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: any) {
		if (error) {
			this.setState({ hasError: true });
			Sentry.captureException(error);
		}
		if (import.meta.env.MODE !== 'production' && error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	}

	render() {
		const { children } = this.props;
		const { hasError } = this.state;

		const refreshPage = () => {
			window.location.reload();
		};

		if (hasError) {
			return (
				<StyledErrorBounds>
					<StyledFeatherBackgroundTop />
					<StyledFeatherBackgroundBottom />
					<StyledH4 mb="16px">Hmmm...</StyledH4>
					<StyledP>There seems to be an issue. Let's try this again.</StyledP>
					<StyledButton color="wine" size="large" onClick={refreshPage}>
						Try Again
					</StyledButton>
				</StyledErrorBounds>
			);
		}

		return children;
	}
}
