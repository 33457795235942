export enum SetStatus {
	APPROVED = 'approved',
	WAITING = 'waiting',
	REJECTED = 'rejected',
}

export enum SKillType {
	QUESTION = 'question',
	TOPIC = 'topic',
	SKILL = 'skill',
}

// If these are changed then the ensure the TipType enum in the backend is updated as well
export enum EditorType {
	DESCRIPTION = 'description',
	STANDARD = 'standard',
	EXTRAINFO = 'extraInfo',
	OUTCOME = 'outcome',
	RESOURCES = 'resources',
	UNITS = 'unit',
	PREREQ = 'preReq',
}

export enum CourseResourceEnum {
	Textbook = 'textbook',
	Media = 'media',
	Info = 'info',
	All = 'all',
}
