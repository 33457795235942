import styled from 'styled-components';
import { media } from '@/Shared/theme';

const StyledIcon = styled.div`
	align-items: center;
	color: var(--label-text);
	cursor: pointer;
	display: flex;

	svg {
		transition: color 0.15s ease-in;
	}

	span {
		display: none;
		margin-left: var(--spacing-2);
		${media.condensed} {
			display: inline;
		}
	}
`;

function SubmissionsIcon() {
	return (
		<StyledIcon>
			<svg viewBox="-6 0 113 114" fill="none" xmlns="http://www.w3.org/2000/svg" width="34">
				<path
					d="M30.913 104.203H17.358c-3.94 0-7.147-3.246-7.147-7.236V55.804c0-.923-.742-1.67-1.656-1.67-.914 0-1.655.747-1.655 1.67v41.163c0 5.831 4.692 10.576 10.458 10.576h13.555c.914 0 1.655-.747 1.655-1.67 0-.922-.741-1.67-1.655-1.67zm-9.779-69.139h27.281c.915 0 1.656-.747 1.656-1.67 0-.922-.741-1.67-1.656-1.67H21.134c-.914 0-1.655.748-1.655 1.67 0 .923.74 1.67 1.655 1.67zm0 36.748c-.914 0-1.655.747-1.655 1.67 0 .922.74 1.67 1.655 1.67H59.45c.915 0 1.656-.748 1.656-1.67 0-.923-.741-1.67-1.656-1.67H21.134zm-.441-23.386h49.402c.914 0 1.655-.747 1.655-1.67 0-.922-.741-1.67-1.655-1.67H20.693c-.914 0-1.655.748-1.655 1.67 0 .923.741 1.67 1.655 1.67zm0 13.364h49.41c.914 0 1.655-.748 1.655-1.67 0-.923-.741-1.67-1.655-1.67h-49.41c-.914 0-1.655.747-1.655 1.67 0 .922.741 1.67 1.655 1.67z"
					fill="currentColor"
				/>
				<path
					d="M106.089 89.897a7.731 7.731 0 00-2.242-5.063L88.34 69.28V33.743c0-2.226-.857-4.32-2.411-5.896L60.883 2.454A8.269 8.269 0 0055.036 0H17.358C11.592 0 6.9 4.745 6.9 10.576V48.01c0 .923.741 1.67 1.655 1.67s1.656-.747 1.656-1.67V10.577c0-3.99 3.206-7.236 7.147-7.236h37.678c.377 0 .746.047 1.104.13v9.396c0 .922.742 1.67 1.656 1.67.914 0 1.655-.748 1.655-1.67v-7.13l23.246 23.569H63.76c-2.376 0-4.309-1.959-4.309-4.366v-4.281c0-.922-.741-1.67-1.655-1.67s-1.656.747-1.656 1.67v4.28c0 4.25 3.419 7.707 7.62 7.707h21.146c.078.356.123.723.123 1.097V67.02a7.664 7.664 0 00-7.986 1.81c-2.983 2.87-2.983 8.038 0 10.908l2.832 2.841h-9.772c-.915 0-1.656.748-1.656 1.67 0 .923.741 1.67 1.656 1.67h13.785c.67 0 1.274-.408 1.53-1.033a1.68 1.68 0 00-.363-1.821l-5.678-5.695c-1.69-1.626-1.69-4.545 0-6.171a4.366 4.366 0 016.18 0l15.962 16.01c1.675 1.606 1.683 4.55-.006 6.165l-15.958 16.005a4.359 4.359 0 01-6.178-.001c-1.69-1.626-1.69-4.546 0-6.172l5.678-5.695a1.68 1.68 0 00.363-1.82 1.655 1.655 0 00-1.53-1.034h-32.13a4.373 4.373 0 01-4.365-4.37 4.372 4.372 0 014.366-4.368h10.62c.913 0 1.654-.748 1.654-1.67 0-.923-.74-1.67-1.655-1.67h-10.62c-4.232 0-7.676 3.458-7.676 7.709 0 4.25 3.444 7.709 7.677 7.709h28.116l-2.832 2.841a7.67 7.67 0 00-1.966 3.365h-36.44c-.914 0-1.655.747-1.655 1.67 0 .922.741 1.67 1.655 1.67h36.256a7.663 7.663 0 002.149 4.202 7.654 7.654 0 0010.849.001l15.949-15.997a7.75 7.75 0 002.249-5.852z"
					fill="currentColor"
				/>
			</svg>
			<span>Submissions</span>
		</StyledIcon>
	);
}

export default SubmissionsIcon;
