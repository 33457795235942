import { createGlobalStyle } from 'styled-components';
import { useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import { useGetAllTags } from '@/Pages/Courses/CourseEditContainers';
import { media } from '@/Shared/theme';

const GlobalTooltipStyle = createGlobalStyle`
.tag-definition-tooltip.__react_component_tooltip {
	background-color: var(--c-white);
	border-radius: 6px;
	border-width: 3px;
	color: var(--body-text);
	display: flex;
	flex-direction: column;
	font-size: 1.5rem;
	max-width: 300px;
	white-space: pre-wrap;
	width: calc(100% - 20px);

	&:after {
		opacity: 0;
	}

	${media.smallScreen} {
		&:after {
			opacity: 1;
		}
	}
}

div.tag-definition-tooltip.__react_component_tooltip.place-left::after {
	border-left-color: inherit;
	right: -8px;
}

div.tag-definition-tooltip.__react_component_tooltip.place-right::after {
	border-right-color: inherit;
	left: -8px;
}

div.tag-definition-tooltip.__react_component_tooltip.place-top::after {
	border-top-color: inherit;
	bottom: -8px;
}

div.tag-definition-tooltip.__react_component_tooltip.place-bottom::after {
	border-bottom-color: inherit;
	top: -8px;
}

div.tag-definition-tooltip.__react_component_tooltip.show {
	opacity: 0.95;
}

.tag-definition-tooltip.__react_component_tooltip strong {
	font-family: var(--font-bold);
	font-size: 1.6rem;
	margin: auto;
}
`;

export function rebuildTooltip() {
	ReactTooltip.rebuild();
}

const TaggedWordTooltip = () => {
	const { data: tagInfo, isLoading } = useGetAllTags();
	const reactTooltipRef = useRef(null);

	const getTagDefinition = (id: string) => {
		const alignment = document?.querySelector('.course-alignment');
		const tooltip = document?.querySelector('.tag-definition-tooltip') as HTMLElement;
		const matchingTag = tagInfo?.find((tag: CourseTag) => tag.tag_id === id);

		if (tooltip) {
			const color = matchingTag?.tag_color?.replace(')', `, 0.4)`)?.replace('rgb', 'rgba');
			tooltip.style.borderColor = color || 'var(--org-color)';
			document.documentElement.style.setProperty(
				'--tooltip-arrow-color',
				color || 'var(--org-color)'
			);
		}
		if (alignment?.classList.contains('hidden-tags')) {
			return null;
		}
		if (matchingTag?.tag_definition) {
			return `<strong>${matchingTag?.tag_name}</strong> ${matchingTag?.tag_definition}`;
		}
		return null;
	};

	if (isLoading) {
		return <div />;
	}

	return (
		<>
			<GlobalTooltipStyle />
			<ReactTooltip
				multiline
				getContent={getTagDefinition}
				data-testid="tooltip"
				className="tag-definition-tooltip"
				html
				arrowColor="var(--tooltip-arrow-color)"
				ref={reactTooltipRef}
				afterShow={() => {
					const { tooltipRef } = reactTooltipRef.current as any;

					if (!tooltipRef) return;
					if (window.innerWidth > 800) return;

					const rect = tooltipRef.getBoundingClientRect();

					const overflownLeft = rect.left < 0;
					const overflownTop = rect.top < 0;
					const overflownRight = rect.right > window.innerWidth;
					const overflownBottom = rect.bottom > window.innerHeight;

					setTimeout(() => {
						if (overflownLeft) {
							tooltipRef.style.setProperty('left', '10px');
							tooltipRef.style.setProperty('right', 'auto');
						}
						if (overflownTop) {
							tooltipRef.style.setProperty('top', '20px');
							tooltipRef.style.setProperty('bottom', 'auto');
						}
						if (overflownRight) {
							tooltipRef.style.setProperty('left', 'auto');
							tooltipRef.style.setProperty('right', '10px');
						}
						if (overflownBottom) {
							tooltipRef.style.setProperty('top', 'auto');
							tooltipRef.style.setProperty('bottom', '20px');
						}
					});
				}}
			/>
		</>
	);
};

export default TaggedWordTooltip;
