import styled from 'styled-components';
import debounce from 'just-debounce-it';

import { useRef, useContext, useEffect, useState } from 'react';
import { TrashCanIcon } from '@/icons/index';
import { EditorType } from '@/Enums/enum';
import TiptapEditor from '@/components/TipTapEditor/TiptapEditor';
import { useCourseEditStore } from '@/stores/courseEditStore';
import useSaveDraft from '@/hooks/useSaveDraft';
import { StyledActions } from './CourseOutcomeTab.Styles';
import CourseEditContext from '../CourseEditContext';

const StyledOutcomeEditor = styled.div`
	align-items: center;
	display: grid;
	grid-template-columns: 1fr 60px;
	margin-bottom: var(--spacing-6);
	width: 100%;
`;

interface OutcomeProps {
	onRemoveEvent(id: string): void;
	id: string;
	text: string;
}

const OutcomeEditor = ({ onRemoveEvent, id, text }: OutcomeProps) => {
	const { canEdit, isApprovalMode, updateCourseReducer, saveDraft } =
		useContext(CourseEditContext);
	const ref = useRef(null);
	const { updateCommentTypes, dynamicDoc, docTypes, dynamicProvider, providers } =
		useCourseEditStore();

	const removeOutcome = (outcomeId: string) => {
		onRemoveEvent(outcomeId);
	};

	const [isCommented, setIsCommented] = useState(false);
	// once a comment is added, state is updated and the draft is saved
	const saveAfterCommenting = (value: string) => {
		updateCourseReducer({ id, debouncedText: value }, EditorType.OUTCOME);
		setIsCommented(!isCommented);
	};
	// once a comment is added, save a draft using hook
	useSaveDraft(isCommented, saveDraft, setIsCommented);

	const onOutcomeChange = debounce((value: string) => {
		updateCourseReducer({ id, debouncedText: value }, EditorType.OUTCOME);
	}, 500);

	useEffect(() => {
		dynamicDoc(id, EditorType.OUTCOME);
		dynamicProvider(id, EditorType.OUTCOME);
	}, [updateCommentTypes, dynamicDoc, id, dynamicProvider]);

	return (
		<StyledOutcomeEditor ref={ref}>
			{docTypes[`${id}${EditorType.OUTCOME}`] && (
				<TiptapEditor
					content={text}
					onChange={onOutcomeChange}
					placeholder="Add course outcome..."
					type={`${EditorType.OUTCOME}-${id}`}
					isEditable={canEdit || !isApprovalMode}
					id={id}
					commentAddedCallback={saveAfterCommenting}
					providerRef={providers[`${id}${EditorType.OUTCOME}`]}
					documentRef={docTypes[`${id}${EditorType.OUTCOME}`]}
				/>
			)}
			{canEdit && !isApprovalMode && (
				<StyledActions>
					<TrashCanIcon passedEvent={() => removeOutcome(id)} />
				</StyledActions>
			)}
		</StyledOutcomeEditor>
	);
};

export default OutcomeEditor;
