import styled from 'styled-components';
import { CloseIcon } from '@/icons/index';

export const StyledModalBody = styled.div`
	display: flex;
	margin-top: var(--spacing-8);
	min-height: 50vh;
	position: relative;

	.plus-icon-btn {
		margin-left: var(--spacing-4);
	}

	.input-column {
		margin-right: var(--spacing-4);
		margin-top: var(--spacing-n2);
		overflow: auto;
		width: 100%;
	}

	.panel-column {
		flex-shrink: 0;
		flex: 0 0 300px;
		margin-top: var(--spacing-n8);
		overflow: hidden;
		position: relative;
	}
`;

export const StyledForm = styled.form`
	column-gap: var(--spacing-6);
	display: grid;
	row-gap: var(--spacing-4);

	.select-wrapper {
		align-items: flex-start;
		grid-column: 1/3;
		margin-bottom: var(--spacing-4);
		width: 100%;
	}

	.input-container {
		flex: auto;
		grid-column: 1/3;
		max-width: none;
		width: 100%;
	}

	.error-message {
		margin-top: calc(var(--spacing-4) * -1);
	}

	.list {
		max-height: 110px;
		overflow: auto;
	}
`;

export const StyledCourseContainer = styled.div`
	max-height: 200px;
	overflow: auto;

	div {
		margin-bottom: var(--spacing-4);
	}
`;

export const StyledFooterBody = styled.div`
	border-top: 1px solid rgba(50, 50, 50, 0.1);
	padding-top: var(--spacing-8);
	text-align: right;
`;

export const StyledModalHeader = styled.div`
	box-shadow: 0 2px 0 -1px rgba(50, 50, 50, 0.1);
	padding-bottom: var(--spacing-4);
`;

export const modalStyles: ReactModal.Styles = {
	content: {
		backgroundColor: 'var(--modal-bg)',
		display: 'flex',
		flexDirection: 'column',
		inset: 'initial',
		margin: 'auto',
		maxHeight: '90%',
		maxWidth: '800px',
		overflow: 'visible',
		position: 'relative',
		width: '95%',
	},
	overlay: {
		backgroundColor: 'var(--modal-overlay)',
		display: 'flex',
		justifyContent: 'center',
		zIndex: 1,
	},
};

export const StyledCloseIcon = styled(CloseIcon as any)`
	background-color: var(--tab-group-color);
`;
