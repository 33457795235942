import { useContext, useState } from 'react';
import styled from 'styled-components';

import GlobalContext from '@/context/GlobalContext';
import { media } from '@/Shared/theme';
import { StyledOrgButton } from '@/Shared/StyledElements';
import PhiloModal from './PhiloModal';

const StyledCard = styled.div<{ color: string }>`
	align-items: center;
	background-color: ${(props) => `${props.color}`};
	border-radius: 6px;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	margin-bottom: var(--spacing-4);
	margin-right: var(--spacing-4);
	min-height: 350px;
	overflow: hidden;
	position: relative;
	width: 60px;

	${media.landscape} {
		width: 150px;
	}

	${media.smallScreen} {
		min-height: auto;
		width: 350px;
	}

	.discipline-content {
		border-radius: 4px;
		flex: 0 0 500px;
		margin: var(--spacing-4);
		padding: var(--spacing-4);
		text-align: center;
		transform: rotate(270deg);

		${media.landscape} {
			flex: 0 0 200px;
		}

		${media.smallScreen} {
			flex: none;
			height: calc(100% - var(--spacing-8));
			text-align: left;
			transform: none;
			width: 350px;
			width: calc(100% - var(--spacing-8));
		}
	}

	.overlay {
		background: ${(props) => `linear-gradient(0deg, ${props.color} 10%, rgb(0 0 0 / 15%))`};
		inset: 0;
		height: 99%;
		position: absolute;
	}

	.sticky-content {
		font-size: 2.5rem;
		top: var(--spacing-6);

		${media.smallScreen} {
			position: sticky;
		}
	}

	${StyledOrgButton} {
		background: var(--neutral-50);
		border: none;
		color: ${(props) => props.color};
		display: none;

		${media.smallScreen} {
			display: block;
		}
	}

	div {
		color: var(--c-white);
		font-family: var(--font-semibold);
		font-size: 2.5rem;

		${media.landscape} {
			font-size: 3rem;
		}

		${media.smallScreen} {
			font-size: 4rem;
			margin-bottom: var(--spacing-4);
		}
	}
`;

interface DisciplineCardProps {
	name: string;
	color: string;
}

const DisciplineCard = ({ name, color }: DisciplineCardProps) => {
	const { disciplines: disciplineData } = useContext(GlobalContext);
	const currentDiscipline = disciplineData?.find((d: any) => d.name === name);
	const [philoModalActive, setPhiloModalActive] = useState(false);

	const triggerModal = () => {
		setPhiloModalActive(!philoModalActive);
	};

	if (!currentDiscipline) return null;

	return (
		<>
			<StyledCard color={color} className="discipline--intro">
				<div className="overlay" />
				<div className="discipline-content">
					<div className="sticky-content">
						<div>{name}</div>
						{currentDiscipline?.philosophy !== '' && (
							<StyledOrgButton
								type="button"
								onClick={() => setPhiloModalActive(!philoModalActive)}>
								View Philosophy
							</StyledOrgButton>
						)}
					</div>
				</div>
			</StyledCard>
			<PhiloModal
				philoModalActive={philoModalActive}
				currentPhilo={currentDiscipline}
				closeEvent={triggerModal}
			/>
		</>
	);
};

export default DisciplineCard;
