import styled from 'styled-components';

const StyledTag = styled.div`
	background-color: var(--label-text);
	border: none;
	border-radius: 6px;
	color: var(--c-white);
	cursor: default;
	display: flex;
	display: inline-block;
	font-family: var(--font-bold);
	font-size: 1.4rem;
	padding: 6px 16px;
`;

type CourseTagsProps = {
	text?: string;
	style?: React.CSSProperties;
};

const CourseTags = ({ text = '', style }: CourseTagsProps) => (
	<StyledTag style={style}>{text}</StyledTag>
);

export default CourseTags;
