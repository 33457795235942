import { media } from '@/Shared/theme';
import styled from 'styled-components';

export const StyledNavbar = styled.nav`
	align-items: center;
	background-color: var(--neutral-50);
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.03);
	display: flex;
	grid-area: nav;
	padding: var(--spacing-4) var(--spacing-6);
	position: relative;
	z-index: 1;

	.logo {
		width: 120px;
	}

	img {
		margin-right: auto;
	}
`;

export const StyledAvatar = styled.button`
	border-radius: 50%;
	border: 4px solid transparent;
	cursor: pointer;
	height: 40px;
	margin-left: var(--spacing-8);
	overflow: hidden;
	padding: 0;
	transition: 0.3s border-color;
	width: 40px;

	${media.smallScreen} {
		height: 50px;
		width: 50px;
	}

	&:focus,
	&:hover {
		border: 4px solid rgb(115 114 149 / 43%);
		outline: none;
	}

	img {
		object-fit: cover;
	}

	div {
		align-items: center;
		background-color: #737295;
		color: #fff;
		display: flex;
		font-family: var(--font-semibold);
		height: 100%;
		justify-content: center;
		line-height: 0;
		user-select: none;
	}
`;

export const StyledOptions = styled.div`
	position: relative;
`;

export const StyleOptionsDrawer = styled.div`
	align-items: center;
	bottom: -50px;
	display: flex;
	height: 100%;
	justify-content: flex-end;
	position: absolute;
	right: 0;
	width: 125px;

	button {
		background-color: var(--neutral-50);
		border-radius: 6px;
		border: 1px solid var(--elevate-primary);
		color: var(--label-text);
		cursor: pointer;
		margin-top: 1px;
		padding: var(--spacing-1) var(--spacing-3);

		&:focus,
		&:hover {
			background-color: var(--elevate-primary);
			color: var(--neutral-50);
		}
	}
`;
