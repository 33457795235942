import styled from 'styled-components';

export const StyledDragItem = styled.div`
	display: flex;
	position: relative;
	margin-bottom: var(--spacing-8);

	.drag-icon-wrapper {
		display: flex;
	}

	.drag-icon {
		margin-right: var(--spacing-2);
	}

	&.dragging .drag-icon {
		background: var(--green-300);
		border-radius: 6px;
	}
`;

export const StyledActions = styled.div`
	align-items: center;
	display: flex;
	height: calc(100% - 46px);
	justify-content: center;
	margin-top: auto;
`;
