import styled, { keyframes } from 'styled-components';

export const StyledActionsContainer = styled.div`
	display: flex;
	justify-content: center;

	> *:not(:last-child) {
		margin-right: var(--spacing-4);
	}
`;

const dash = keyframes`
	from {
		fill: transparent;
		stroke-dashoffset: 350;
	}	
	to {
		fill: hsla(0, 0%, 100%, 0.6);
		stroke-dashoffset: 0;
	}
`;

export const StyledOverlay = styled.div`
	align-items: center;
	background-color: var(--modal-overlay);
	display: flex;
	flex-direction: column;
	height: 100%;
	inset: 0;
	justify-content: center;
	opacity: 1;
	padding: var(--spacing-6);
	position: fixed;
	width: 100%;
	z-index: 1;

	h4 {
		color: var(--c-white);
	}

	svg {
		margin-bottom: var(--spacing-6);
		max-width: 180px;
	}

	path {
		animation: ${dash} 5s linear alternate infinite;
		fill: transparent;
		stroke-dasharray: 350;
		stroke-dashoffset: 10;
		stroke-width: 0.5px;
		stroke: var(--c-white);
	}
`;
