import styled from 'styled-components';
import { media } from '@/Shared/theme';

const StyledIcon = styled.div`
	align-items: center;
	color: var(--label-text);
	cursor: pointer;
	display: flex;

	svg {
		transition: color 0.15s ease-in;
	}

	path {
		fill: currentColor;
	}

	span {
		display: none;
		margin-left: var(--spacing-2);
		${media.condensed} {
			display: inline;
		}
	}
`;

const EditDivisionIcon = () => (
	<StyledIcon>
		<svg width="35" viewBox="0 0 113 113" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M48.1 105.8V72a7 7 0 0 0-7-7H7A7 7 0 0 0 0 72v33.8a7 7 0 0 0 7 7h34a7 7 0 0 0 7.1-7Zm-43 0V72c0-1 1-2 2-2h34c1 0 1.9 1 1.9 2v33.8c0 1-.9 2-2 2H7c-1 0-1.8-1-1.8-2ZM64.1 105.8a7 7 0 0 0 7 7h34a7 7 0 0 0 7.1-7V72a7 7 0 0 0-7-7h-34a7 7 0 0 0-7 7v33.8ZM69.3 72c0-1 .8-2 1.9-2h34c1 0 1.9 1 1.9 2v33.8c0 1-.9 2-2 2h-34c-1 0-1.8-1-1.8-2V72ZM64.1 40.9a7 7 0 0 0 7 7h34a7 7 0 0 0 7.1-7V7a7 7 0 0 0-7-7h-34a7 7 0 0 0-7 7V41ZM69.3 7c0-1 .8-1.9 1.9-1.9h34c1 0 1.9.9 1.9 2v33.8c0 1-.9 1.9-2 1.9h-34c-1 0-1.8-.9-1.8-2V7ZM0 40.9a7 7 0 0 0 7 7h34a7 7 0 0 0 7.1-7v-11a2.6 2.6 0 0 0-5.1 0v11c0 1-.9 1.9-2 1.9H7c-1 0-1.8-.9-1.8-2V7C5.2 6 6 5.1 7 5.1h34c1 0 1.9.9 1.9 2V18a2.6 2.6 0 0 0 5.1 0V7a7 7 0 0 0-7-7H7A7 7 0 0 0 0 7V41Z" />
		</svg>
		<span>Divisions</span>
	</StyledIcon>
);

export default EditDivisionIcon;
