import styled from 'styled-components';

type StyledIconProps = {
	top?: string;
	right?: string;
};

const StyledIcon = styled.button<StyledIconProps>`
	background-color: var(--orange-btn);
	border: none;
	cursor: pointer;
	line-height: 0;
	border-radius: 50%;
	padding: 12px;
	position: absolute;
	background: none;
	border: none;
	top: ${(props) => props.top};
	right: ${(props) => props.right};

	&:focus,
	&:hover {
		background-color: var(--input-border);
		outline: none;
	}
`;

interface CloseIconProps {
	passedEvent(): void;
	top?: string;
	right?: string;
	className?: string;
}

const CloseIcon = ({
	passedEvent,
	top = '16px',
	right = '24px',
	className = '',
}: CloseIconProps) => (
	<StyledIcon onClick={passedEvent} top={top} right={right} className={className} type="button">
		<svg
			aria-hidden="true"
			width="12"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="#999FA3">
			<path d="M23.954 21.03l-9.184-9.095 9.092-9.174L21.03-.046l-9.09 9.179L2.764.045l-2.81 2.81L9.14 11.96.045 21.144l2.81 2.81 9.112-9.192 9.18 9.1z" />
		</svg>
		<span className="screen-reader-text">Clear/Close</span>
	</StyledIcon>
);

export default CloseIcon;
