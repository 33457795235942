import Tag from '@/components/Tag/Tag';
import { StyledSubHeader } from '@/Shared/Typography/typography';
import styled from 'styled-components';

export const StyledCardDeck = styled.div`
	display: grid;
	grid-gap: 32px;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	overflow-y: auto;

	> div {
		height: 100%;
		margin-bottom: var(--spacing-4);
		margin-right: var(--spacing-4);
	}
`;

export const StyledCard = styled.div`
	background-color: var(--card-bg-color);
	border-radius: 6px;
	box-shadow: 0px 2px 4px 1px rgba(0, 15, 25, 0.05), 0px 0px 4px 0 rgba(0, 15, 25, 0.05);
	display: inline-block;
	padding: var(--spacing-4);
	width: 100%;
	height: 100%;

	${StyledSubHeader} {
		font-weight: bold;
	}

	small {
		color: var(--header-text);
		font-family: var(--font-regular);
		font-style: italic;
		font-size: 1.1rem;
	}

	&.truncated-height {
		overflow: auto;
	}
`;

export const StyledTag = styled(Tag as any)`
	margin-bottom: var(--spacing-2);
`;

export const StyledLegendItem = styled.div<{ color: string }>`
	margin-left: var(--spacing-4);
	position: relative;

	&:before {
		background-color: ${(props) => props.color};
		border-radius: 50%;
		content: '';
		height: 10px;
		left: calc(var(--spacing-4) * -1);
		opacity: 0.8;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 10px;
	}
`;
