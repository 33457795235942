import styled from 'styled-components';

export const StyledPanel = styled.div`
	width: 100%;

	form {
		border-bottom: 1px solid rgba(50, 50, 50, 0.4);
		margin-bottom: var(--spacing-8);
	}

	label,
	.label {
		font-size: 1.2rem;
	}

	.course-division,
	.course-discipline {
		margin-bottom: var(--spacing-2);
	}

	.course-grade {
		margin-bottom: var(--spacing-4);
	}

	.input-container {
		margin-bottom: var(--spacing-2);
		max-width: none;
		width: 100%;
	}

	button[type='submit'] {
		margin: var(--spacing-4) var(--spacing-4) var(--spacing-4) 0;
	}
`;
