import styled from 'styled-components';
import { ToastContainer, toast, Flip, ToastContainerProps } from 'react-toastify';

const StyledToastContainer = styled(ToastContainer)`
	.Toastify__toast {
		font-family: var(--font-regular);
	}

	.Toastify__toast--success {
		--toastify-color-progress-success: var(--success-message);
		--toastify-icon-color-success: var(--success-message);
	}

	.Toastify__toast--error {
		--toastify-color-progress-warning: var(--error-color);
		--toastify-icon-color-warning: var(--error-color);

		&.Toastify__toast-theme--colored {
			background-color: var(--red-600);
		}
	}
`;

const toastOptions: ToastContainerProps = {
	transition: Flip,
	position: toast.POSITION.BOTTOM_RIGHT,
	autoClose: 2000,
	closeOnClick: true,
	role: 'alert',
	pauseOnHover: false,
};

export const ToastNotification = () => (
	<StyledToastContainer
		style={{
			width: 'auto',
			minWidth: '200px',
		}}
	/>
);

export const showSuccessToast = (message: string, options = toastOptions) => {
	toast.success(message, options);
};

export const showErrorToast = (message: string, options = toastOptions) => {
	toast.error(message, options);
};
