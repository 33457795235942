import { useContext } from 'react';
import { diffWords } from 'diff';
import styled from 'styled-components';

import { StyledH6, StyledP, StyledSubHeader } from '@/Shared/Typography/typography';
import { StyledEditor } from '@/components/TipTapEditor/TipTapEditor.Styles';
import CourseEditContext from './CourseEditContext';

const StyledDiff = styled.div`
	.diff-content {
		display: flex;
	}

	${StyledEditor} .tiptap-container {
		min-width: auto;
	}

	.diff-item {
		margin-right: 24px;
		width: calc(33% - 24px);
	}

	p:empty {
		margin-bottom: var(--spacing-6);
	}
`;

interface CourseDiffProps {
	original: string;
	draft: string;
	title?: string;
}

const CourseDiff = ({ original, draft, title }: CourseDiffProps) => {
	const { isApprovalMode } = useContext(CourseEditContext);

	const regex = /<(?!\/?(iframe|br)\b)[^>]+>/gm; // remove all tags except <br> and <iframe>
	// remove all tags except <br> and <iframe> and replace <iframe> with (VIDEO)
	const removeHTMLTags = (str: string) =>
		str?.replace(regex, '')?.replace(/<iframe[^>]*>[\s\S]*?<\/iframe>/gm, ' (VIDEO) ') ?? '';

	const sanitizedOriginal = removeHTMLTags(original);
	const sanitizedDraft = removeHTMLTags(draft);

	const diff = diffWords(sanitizedOriginal, sanitizedDraft);

	let fragment = ``;
	let span = ``;

	const removedStyle = `
		color: var(--elevate-primary);
		background-color: var(--red-200);
	`;
	const removedIframeStyle = `box-shadow: 0px 0px 0px 4px var(--red-200); display: inline-block; line-height: 0;`;
	const addedStyle = `color: var(--elevate-primary); background-color: var(--green-200);`;
	const addedIframeStyle = `box-shadow: 0px 0px 0px 4px var(--green-200); display: inline-block; line-height: 0;`;

	diff.forEach((part) => {
		if (part?.added && part?.value?.includes('<iframe')) {
			span = `<span style="${addedIframeStyle}">${part?.value}</span>`;
			fragment += span;
			return;
		}
		if (part?.removed && part?.value?.includes('<iframe')) {
			span = `<span style="${removedIframeStyle}">${part?.value}</span>`;
			fragment += span;
			return;
		}
		if (part?.added) {
			span = `<span style="${addedStyle}">${part?.value}</span>`;
			fragment += span;
			return;
		}
		if (part?.removed) {
			span = `<span style="${removedStyle}">${part?.value}</span>`;
			fragment += span;
			return;
		}
		span = `<span style="color: #484969">${part?.value}</span>`;
		fragment += span;
	});

	const checkForEmptyVal = (str: string) =>
		str?.replace(/ style="([^"]*)"/g, '')?.replaceAll(/<[^>]*>/gm, '');

	const results = checkForEmptyVal(fragment) === '' ? '-' : fragment;

	if (!isApprovalMode) {
		return null;
	}

	return (
		<StyledDiff>
			{title && <StyledH6 mb="8px">{title}</StyledH6>}
			<div className="diff-content">
				<div className="diff-item">
					<StyledSubHeader mb="8px">Current Version</StyledSubHeader>
					<StyledP
						dangerouslySetInnerHTML={{
							__html: checkForEmptyVal(sanitizedOriginal) === '' ? '-' : original,
						}}
					/>
				</div>
				<div className="diff-item">
					<StyledSubHeader mb="8px">Proposed Draft</StyledSubHeader>
					{original !== draft && (
						<StyledP
							dangerouslySetInnerHTML={{
								__html: checkForEmptyVal(sanitizedDraft) === '' ? '-' : draft,
							}}
						/>
					)}
					{original === draft && <StyledP>-</StyledP>}
				</div>
				<div className="diff-item">
					<StyledSubHeader mb="8px">Revisions</StyledSubHeader>
					{original !== draft && (
						<StyledP
							dangerouslySetInnerHTML={{
								__html: results,
							}}
						/>
					)}
					{original === draft && <StyledP>-</StyledP>}
				</div>
			</div>
		</StyledDiff>
	);
};

export default CourseDiff;
