import styled from 'styled-components';
import { media } from '@/Shared/theme';

const StyledIcon = styled.div`
	align-items: center;
	color: var(--label-text);
	cursor: pointer;
	display: flex;

	svg {
		transition: color 0.15s ease-in;
	}

	span {
		display: none;
		margin-left: var(--spacing-2);

		${media.condensed} {
			display: inline;
		}
	}
`;

function SwitchIcon() {
	return (
		<StyledIcon>
			<svg viewBox="0 0 108 108" fill="none" width="35" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#SwitchIcon_svg__clip0)" fill="currentColor">
					<path d="M77.91.887L49.495 36.972c-.81 1.03-.078 2.549 1.236 2.549h18.316v25.415a1.574 1.574 0 103.149 0v-26.99c0-.87-.705-1.574-1.575-1.574H53.976l25.14-31.928 24.159 32.212H87.387c-.87 0-1.575.705-1.575 1.575V74.6c0 14.156-8.388 22.712-22.069 25.399a31.433 31.433 0 002.523-3.418c6.19-9.672 5.931-19.732 5.931-25.454a1.574 1.574 0 10-3.149 0c0 4.465.499 14.37-5.344 23.614-2.644 4.183-5.327 6.114-5.35 6.13-1.274.893-.61 2.906.946 2.864.301-.008 7.467-.241 14.73-3.897 6.81-3.428 14.93-10.588 14.93-25.237V39.805h17.463c1.296 0 2.036-1.483 1.26-2.519L80.406.916a1.574 1.574 0 00-2.497-.03z" />
					<path d="M33.97 8.162C27.16 11.59 19.04 18.752 19.04 33.4v4.039a1.574 1.574 0 103.148 0V33.4c0-14.157 8.389-22.712 22.069-25.4-5.892 6.929-8.453 16.202-8.453 25.4v36.654c0 .87.704 1.575 1.574 1.575h16.646l-25.14 31.927L4.725 71.344h15.889c.87 0 1.574-.704 1.574-1.574V43.42a1.574 1.574 0 10-3.149 0v24.774H1.576c-1.294 0-2.037 1.483-1.26 2.52l27.278 36.369c.62.827 1.857.841 2.497.029l28.413-36.085c.813-1.032.075-2.548-1.236-2.548H38.952V33.4c0-9.66 2.907-16.285 5.344-20.142 2.644-4.182 5.328-6.114 5.35-6.13 1.274-.891.61-2.905-.945-2.863-.302.008-7.467.24-14.73 3.897z" />
				</g>
			</svg>
			<span>Alignment</span>
		</StyledIcon>
	);
}

export default SwitchIcon;
