import styled from 'styled-components';

const StyledSVG = styled.div`
	align-items: center;
	display: flex;
`;

const DragIcon = () => (
	<StyledSVG className="drag-icon">
		<svg width="24" viewBox="0 0 24 24" aria-hidden="true">
			<path
				fill="#484969"
				d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
			/>
		</svg>
	</StyledSVG>
);

export default DragIcon;
