import { createGlobalStyle } from 'styled-components';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import elevateLogo from '@/assets/logo.svg';
import { isLocalStorageAvailable } from '@/utils';

const GlobalStepsStyle = createGlobalStyle`
	.driver-popover-close-btn {
		border-radius: 50%;
		color: var(--elevate-primary);
		flex-shrink: 0;
		font-weight: 500;
		height: 25px;
		width: 25px;
		margin: 4px;

		&:focus,
		&:hover {
			background-color: var(--input-border);
		}
	}

	.driver-popover-title {
		color: var(--elevate-primary);
		font-family: var(--font-semibold);
	}

	.driver-popover {
		padding: 24px 16px;
	}

	.driver-popover-description {
		color: var(--elevate-primary);
		font-family: var(--font-regular);

		img {
			max-width: 200px;
			margin: auto;
		}
	}

	.driver-popover-navigation-btns button+button {
		margin-left: var(--spacing-4);
	}

	.driver-popover-footer button {
		border-radius: 6px;
		border: 2px solid;
		cursor: pointer;
		font-family: var(--font-semibold);
		font-size: 15px;
		line-height: 1;
		padding: var(--spacing-2) var(--spacing-4);
		text-shadow: none;
		transition: background-color 0.3s, box-shadow 0.3s, color 0.3s, border-color 0.3s;

		&:hover,
		&:focus {
			background-color: var(--wine-btn-active);
			border-color: var(--wine-btn-active);
			box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
				color: var(--c-white);
			outline: none;
		}
	}

	button.driver-popover-next-btn {
		background-color: var(--wine-btn);
		border-color: var(--wine-btn);
		color: var(--neutral-50);
		cursor: pointer;
	}

	button.driver-popover-prev-btn {
		background-color: transparent;
		border-color: var(--wine-btn);
		color: var(--wine-btn);
	}

	.has-default-height {
		display: flex !important;
		flex-direction: column;
		min-height: 275px;

		.driver-popover-footer {
			margin-top: auto;
		}
	}
`;

interface ParentMapIntroProps {
	isEnabled: boolean;
	setIsEnabled: (value: boolean) => void;
}

const ParentMapIntro = ({ isEnabled, setIsEnabled }: ParentMapIntroProps) => {
	const handleExit = () => {
		(document?.querySelector('.parent-course-panel-close-btn') as HTMLElement)?.click();
		if (isLocalStorageAvailable()) {
			localStorage.setItem('elevateCourseMapTutorial', 'false');
		}
		setIsEnabled(false);
	};

	const driverObj = driver({
		showProgress: true,
		nextBtnText: 'Next',
		prevBtnText: 'Previous',
		onDestroyed: () => {
			handleExit();
		},
		steps: [
			{
				popover: {
					popoverClass: 'has-default-height',
					description: `<img src=${elevateLogo}><br />Welcome to Elevate - a custom curriculum mapping software. Press next or the right arrow key to learn more.`,
					align: 'center',
				},
			},
			{
				popover: {
					popoverClass: 'has-default-height',
					title: 'Privacy Consent',
					description: `By using this application, you consent to the following: The information on this site may not be reproduced, transmitted, or copied without the express written permission of Stakk IO.`,
					align: 'center',
				},
			},
			{
				element: '.map-legend',
				popover: {
					title: 'Map Legend',
					description:
						'This legend helps you to identify the course disciplines and divisions as you navigate through the map.',
					align: 'end',
				},
			},
			{
				element: '#course-map',
				popover: {
					title: 'Course Map',
					description:
						'Each discipline track navigates you through the progression of courses. You can navigate the map similar to Google maps - pinching or dragging to view different parts of the map.<img src="https://res.cloudinary.com/stakk-io/image/upload/v1652761855/elevate-defaults/map-pan-demo_dxux5n_n1odmy.gif" />',
					align: 'start',
				},
			},
			{
				element: '#depth-1-node-1',
				popover: {
					title: 'Course/Discipline Card',
					description: 'Each card highlights a specific discipline or course.',
					onNextClick: () => {
						if (!document.querySelector(`[id^='depth-2-node'] .view-button`)) {
							const firstCard = document.getElementById('depth-1-node-1');
							const clickEvent = new Event('click');
							firstCard?.dispatchEvent(clickEvent);
						}
						driverObj.moveNext();
					},
				},
			},
			{
				element: '#depth-1-node-1',
				popover: {
					title: 'Expanding Course Track',
					description:
						'Click any discipline card to expand or collapse each course track.',
					align: 'end',
				},
			},
			{
				element: `[id^='depth-2-node'] .view-button`,
				popover: {
					title: 'View Button',
					description:
						'If you would like to learn more about a course click the view button.',
				},
			},
			{
				element: '.map-controls',
				popover: {
					title: 'Additional Controls',
					description:
						'These are useful map controls. Use the controls to zoom in/out and to reset the map to its original position.',
				},
			},
			{
				element: '.map-search-bar',
				popover: {
					title: 'Search Bar',
					description:
						'If you are looking for a specific course, it may be easier to find it via the search tool. Click done and give it a try!',
					align: 'start',
				},
			},
		],
	});

	if (isEnabled) {
		// reset map to ensure correct elements are in view during tour
		(document.querySelector('#pan-reset') as HTMLButtonElement)?.click();
		driverObj.drive();
	}

	return <GlobalStepsStyle />;
};

export default ParentMapIntro;
