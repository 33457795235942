import React, { createContext, useRef, useState } from 'react';

interface IParentMapContext {
	courseCoordsArray: any;
	d3InstanceRef: any;
	zoom: any;
	duration: number;
	setCourseCoordsArray(a: any): void;
}

const ParentMapContext = createContext({} as IParentMapContext);

const ParentMapContextProvider = ({ ...props }: any) => {
	const { children } = props;
	const [courseCoordsArray, setCourseCoordsArray] = useState<any>();
	const zoom = useRef({}) as any;
	const d3InstanceRef = React.useRef(null) as any;
	const duration = 750;

	return (
		<ParentMapContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				courseCoordsArray,
				zoom,
				d3InstanceRef,
				duration,
				setCourseCoordsArray,
			}}>
			{children}
		</ParentMapContext.Provider>
	);
};

export default ParentMapContext;

export { ParentMapContextProvider };
