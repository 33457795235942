import styled from 'styled-components';

export const StyledPdfTable = styled.div`
	// hides the table but allow jsPdf to reference it
	display: none;

	table [align='left'] {
		background-color: #e6e6e6;
		font-weight: 400;
	}

	th,
	td {
		padding: 10px;
	}

	td:not(.group) {
		padding-bottom: 20px;
	}

	.pdf-course-title {
		font-size: 30px;
		font-weight: 500;
		padding-left: 0;
	}

	.indent-2 {
		padding-left: 20px;
		padding: 0 0 0 20px;
	}
`;
