import { Mark, mergeAttributes } from '@tiptap/react';

export interface CommentOptions {
	HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
	interface Commands<ReturnType> {
		tag: {
			/**
			 * Set a comment mark
			 */
			setTag: () => ReturnType;
			/**
			 * Unset a comment mark
			 */
			unsetTag: () => ReturnType;
		};
	}
}

export const Tagged = Mark.create<CommentOptions>({
	name: 'tagged',

	addOptions() {
		return {
			inline: true,
			HTMLAttributes: {},
		};
	},

	addAttributes() {
		return {
			class: {
				default: null,
			},
			'data-tip': {
				default: null,
			},
			'data-comment': {
				default: null,
			},
			title: {
				default: null,
			},
			style: {
				default: null,
			},
		};
	},

	addCommands() {
		return {
			setTag:
				() =>
				({ commands }) => {
					return commands.setMark('tagged');
				},
		};
	},

	parseHTML() {
		return [
			{
				tag: 'span',
				getAttrs: (element) => {
					const hasStyles = (element as HTMLElement).classList.contains('tagged-word');

					if (!hasStyles) {
						(element as HTMLElement).style.backgroundColor = '';
						return false;
					}

					return {};
				},
			},
		];
	},

	renderHTML({ HTMLAttributes }) {
		return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
	},

	// addProseMirrorPlugins() {
	// 	return [
	// 		new Plugin({
	// 			props: {
	// 				handleClick(view, pos) {
	// 					const { schema, doc, tr } = view.state;

	// 					const range = getMarkRange(doc.resolve(pos), schema.marks.comment);

	// 					if (!range) return false;

	// 					const [$start, $end] = [doc.resolve(range.from), doc.resolve(range.to)];

	// 					// view.dispatch(tr.setSelection(new TextSelection($start, $end)));

	// 					return true;
	// 				},
	// 			},
	// 		}),
	// 	];
	// },
});
