import styled from 'styled-components';

export const StyledActionGroup = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
`;

export const StyledCourseFilters = styled.div`
	display: flex;

	label {
		align-self: flex-end;
		background-color: var(--purple-50);
		border-radius: 4px;
		margin-bottom: var(--spacing-6);
		margin-left: auto;
		margin-right: var(--spacing-5);
		min-width: 165px;
		padding: var(--spacing-1) var(--spacing-2);
	}
`;
