const FeatherBackground = ({ className = 'elevate-feathers' }) => (
	<svg
		role="img"
		aria-busy="true"
		className={className}
		viewBox="0 0 672 489"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<g opacity=".5">
			<path
				d="M496.313 76.136l-1.237-.133-10.224 6.493 9.822 3.046 8.294-1.093c.446-4.147-2.533-7.869-6.655-8.313z"
				fill="#FC604B"
			/>
			<path
				d="M467.547 94.05l-2.207-.238.491.615c2.064 2.579 4.73 5.939 4.757 5.973a.783.783 0 001.104.124.793.793 0 00.124-1.11c-.024-.031-2.294-2.895-4.269-5.364zM495.157 101.183a7.486 7.486 0 01-6.613 2.726l-8.809-.949c-4.122-.445-5.325-3.087-9.778-8.65l26.86 2.895a7.529 7.529 0 01-1.66 3.978z"
				fill="#FFA68C"
			/>
			<path
				d="M472.614 74.887l-9.882-1.065-10.225 6.494 11.835 1.275 8.272-6.704zM282.335 132.398l-9.882-1.065-10.225 6.494 11.835 1.275 8.272-6.704z"
				fill="#4AC4E2"
			/>
			<path
				d="M408.696 80.742l-5.211-2.56c-3.722-1.826-6.151-.236-12.887 2.087l8.031 3.943 10.067-3.47z"
				fill="#03C5AF"
			/>
			<path
				d="M412.545 90.233l-1.116-.549-11.83 2.606 8.189 6.22 8.167 1.81c1.838-3.743.311-8.26-3.41-10.087zM428.71 136.493l-1.116-.548-11.829 2.605 8.188 6.222 8.167 1.809c1.838-3.744.312-8.26-3.41-10.088z"
				fill="#FC604B"
			/>
			<path
				d="M482.58 159.606l-4.187 4.022c-2.99 2.872-2.326 5.699-2.448 12.823l6.453-6.198.182-10.647z"
				fill="#03C5AF"
			/>
			<path
				d="M492.815 159.236l-.897.862-1.598 12.007 8.647-5.568 4.493-7.055c-2.889-3.008-7.655-3.118-10.645-.246zM603.226 76.258l-1.048.67-3.905 11.466 9.565-3.777 5.782-6.045c-2.248-3.513-6.902-4.55-10.394-2.314zM532.425 197.225l-1.048.671-3.905 11.466 9.565-3.777 5.782-6.045c-2.249-3.513-6.903-4.549-10.394-2.315zM287.58 269.757l-1.048.67-3.905 11.466 9.565-3.777 5.781-6.045c-2.248-3.513-6.902-4.549-10.393-2.314zM247.502 92.634l-1.048.67-3.905 11.466 9.565-3.777 5.781-6.045c-2.248-3.513-6.902-4.55-10.393-2.314z"
				fill="#FC604B"
			/>
			<path
				d="M592.017 108.238l-1.87 1.197.768.171c3.224.718 7.407 1.669 7.45 1.678a.78.78 0 00.937-.594.793.793 0 00-.598-.944c-.038-.009-3.601-.819-6.687-1.508zM521.216 229.206l-1.871 1.197.769.172c3.224.718 7.407 1.669 7.449 1.678a.781.781 0 00.938-.594.794.794 0 00-.598-.944c-.039-.009-3.602-.82-6.687-1.509zM276.37 301.738l-1.87 1.197.769.171c3.224.718 7.407 1.669 7.449 1.678a.781.781 0 00.938-.594.794.794 0 00-.598-.944c-.039-.009-3.602-.819-6.688-1.508zM618.01 96.51a7.49 7.49 0 01-3.45 6.267l-7.462 4.775c-3.492 2.235-6.085.928-13.04-.62l22.755-14.563a7.546 7.546 0 011.197 4.14z"
				fill="#FFA68C"
			/>
			<path
				d="M583.967 90.124l-8.371 5.358-3.906 11.466 10.026-6.416 2.251-10.408zM513.166 211.092l-8.372 5.358-3.905 11.466 10.025-6.417 2.252-10.407zM268.321 283.624l-8.372 5.358-3.905 11.466 10.025-6.416 2.252-10.408z"
				fill="#4AC4E2"
			/>
			<path
				d="M251.307 436.461l-4.781 3.293c-3.414 2.352-3.215 5.248-4.486 12.26l7.369-5.076 1.898-10.477z"
				fill="#03C5AF"
			/>
			<path
				d="M261.468 437.747l-1.025.706-3.515 11.591 9.432-4.098 5.574-6.238c-2.366-3.434-7.052-4.312-10.466-1.961zM319.992 90.794l-.627-1.075-11.296-4.372 3.381 9.712 5.803 6.025c3.602-2.102 4.828-6.71 2.739-10.29zM52.115 321.035l-.626-1.074-11.296-4.373 3.38 9.713 5.804 6.024c3.602-2.102 4.828-6.71 2.738-10.29z"
				fill="#FC604B"
			/>
			<path
				d="M350.54 53.596l-5.713 1.035c-4.079.739-5.089 3.461-9.131 9.33l8.804-1.596 6.04-8.769zM82.664 283.837l-5.713 1.035c-4.079.739-5.09 3.462-9.131 9.33l8.804-1.595 6.04-8.77z"
				fill="#03C5AF"
			/>
			<path
				d="M359.272 58.948l-1.223.222-7.973 9.12 10.283.144 7.646-3.393c-.743-4.104-4.653-6.832-8.733-6.093zM91.396 289.189l-1.224.222-7.972 9.119 10.283.145 7.646-3.393c-.743-4.103-4.654-6.832-8.733-6.093zM183.319 292.271l-1.224.222-7.972 9.12 10.283.144 7.646-3.393c-.744-4.103-4.654-6.832-8.733-6.093zM303.817 148.347l-1.224.221-7.972 9.12 10.283.144 7.646-3.392c-.744-4.104-4.654-6.832-8.733-6.093zM457.63 216.183l-.999.74-3.115 11.706 9.286-4.421 5.356-6.425c-2.483-3.351-7.197-4.068-10.528-1.6zM360.545 80.723l-.999.74-3.115 11.706 9.286-4.42 5.356-6.426c-2.483-3.351-7.196-4.068-10.528-1.6zM92.67 310.965l-1 .741-3.115 11.705 9.286-4.42 5.356-6.426c-2.483-3.351-7.197-4.067-10.528-1.6z"
				fill="#FC604B"
			/>
			<path
				d="M448.628 248.854l-1.784 1.321.778.119c3.266.497 7.504 1.16 7.546 1.166a.78.78 0 00.895-.657.793.793 0 00-.661-.9c-.039-.007-3.649-.572-6.774-1.049zM351.543 113.394l-1.784 1.321.778.119c3.266.497 7.504 1.16 7.547 1.166a.781.781 0 00.895-.656.794.794 0 00-.661-.901c-.039-.007-3.649-.572-6.775-1.049zM83.667 343.635l-1.785 1.322.779.119c3.265.496 7.503 1.159 7.546 1.166.43.067.83-.227.895-.657a.793.793 0 00-.66-.901c-.04-.006-3.65-.572-6.775-1.049zM473.76 235.38a7.486 7.486 0 01-3.014 6.488l-7.119 5.273c-3.331 2.468-6.007 1.341-13.051.27l21.708-16.081a7.538 7.538 0 011.476 4.05zM376.676 99.92a7.489 7.489 0 01-3.014 6.488l-7.119 5.273c-3.332 2.468-6.008 1.341-13.052.27l21.708-16.08a7.54 7.54 0 011.477 4.05zM108.799 330.161a7.484 7.484 0 01-3.014 6.488l-7.119 5.274c-3.331 2.467-6.007 1.34-13.051.269l21.708-16.08a7.535 7.535 0 011.476 4.049z"
				fill="#FFA68C"
			/>
			<path
				d="M439.362 231.331l-7.987 5.916-3.114 11.706 9.564-7.085 1.537-10.537zM342.277 95.871l-7.987 5.916-3.114 11.706 9.564-7.085 1.537-10.537zM74.401 326.113l-7.987 5.917-3.114 11.705 9.564-7.085 1.537-10.537z"
				fill="#4AC4E2"
			/>
			<path
				d="M373.594 283.231l-5.565 1.652c-3.975 1.179-4.682 3.995-8.06 10.269l8.577-2.545 5.048-9.376z"
				fill="#03C5AF"
			/>
			<path
				d="M382.858 287.598l-1.192.354-6.93 9.935 10.237-.978 7.231-4.207c-1.187-3.998-5.371-6.283-9.346-5.104zM402.199 360.213l-1.192.353-6.93 9.935 10.237-.978 7.231-4.206c-1.187-3.999-5.371-6.284-9.346-5.104zM225.387 157.81l-1.193.354-6.93 9.934 10.238-.977 7.23-4.207c-1.187-3.998-5.371-6.283-9.345-5.104z"
				fill="#FC604B"
			/>
			<path
				d="M471.162 305.976l-.352 5.795c-.251 4.138 2.154 5.766 6.894 11.086l.541-8.931-7.083-7.95z"
				fill="#03C5AF"
			/>
			<path
				d="M478.434 298.764l-.075 1.242 6.965 9.91 2.582-9.955-1.479-8.232c-4.163-.253-7.742 2.897-7.993 7.035zM375.419 343.98l-1.186.373-6.763 10.049 10.219-1.149 7.159-4.326c-1.253-3.978-5.475-6.193-9.429-4.947zM198.607 141.577l-1.187.374L190.657 152l10.22-1.149 7.159-4.326c-1.253-3.978-5.475-6.193-9.429-4.948z"
				fill="#FC604B"
			/>
			<path
				d="M356.25 371.925l-2.118.667.697.367c2.924 1.534 6.714 3.544 6.752 3.564a.782.782 0 001.061-.329.793.793 0 00-.331-1.066c-.035-.019-3.263-1.732-6.061-3.203zM179.437 169.522l-2.118.667.697.366c2.925 1.535 6.714 3.545 6.753 3.565a.78.78 0 001.06-.329.793.793 0 00-.331-1.067c-.035-.019-3.262-1.731-6.061-3.202zM384.403 367.389a7.487 7.487 0 01-4.966 5.149l-8.45 2.662c-3.954 1.245-6.116-.693-12.426-4.004l25.768-8.117a7.543 7.543 0 01.074 4.31zM207.59 164.986a7.487 7.487 0 01-4.966 5.15l-8.45 2.661c-3.954 1.246-6.116-.693-12.425-4.003l25.767-8.117a7.531 7.531 0 01.074 4.309z"
				fill="#FFA68C"
			/>
			<path
				d="M353.208 352.338l-9.48 2.986-6.764 10.049 11.353-3.577 4.891-9.458zM176.395 149.934l-9.48 2.987-6.763 10.048 11.353-3.576 4.89-9.459z"
				fill="#4AC4E2"
			/>
			<path
				d="M279.677 381.629l-5.8-.255c-4.142-.182-5.729 2.249-10.969 7.078l8.938.392 7.831-7.215z"
				fill="#03C5AF"
			/>
			<path
				d="M287.009 388.781l-1.243-.055-9.792 7.129 9.996 2.416 8.207-1.616c.183-4.167-3.027-7.693-7.168-7.874zM320.365 424.679l-1.243-.055-9.792 7.13 9.996 2.416 8.207-1.617c.183-4.166-3.027-7.692-7.168-7.874z"
				fill="#FC604B"
			/>
			<path
				d="M359.778 408.812l-2.223 5.363c-1.588 3.83.154 6.153 2.899 12.728l3.426-8.265-4.102-9.826z"
				fill="#03C5AF"
			/>
			<path
				d="M369.006 404.369l-.477 1.149 3.35 11.64 5.69-8.567 1.288-8.265c-3.853-1.597-8.264.213-9.851 4.043z"
				fill="#FC604B"
			/>
		</g>
	</svg>
);

export default FeatherBackground;
