import styled, { css } from 'styled-components';

interface ButtonIcon {
	outlined?: boolean;
}

const outlinedStyled = `
	svg {
		height: 20px;
		width: 20px;
	}
`;

const defaultStyles = `
	svg {
		width: 25px;
	}
`;

const StyledIcon = styled.button<ButtonIcon>`
	align-items: center;
	background-color: ${(props) => (props.outlined ? ' rgba(230, 76, 101, 0.5)' : 'transparent')};
	border-radius: 50%;
	border: none;
	cursor: pointer;
	display: inline-flex;
	padding: var(--spacing-2);
	transition: background-color 0.3s;

	&[disabled] {
		opacity: 0.3;
		pointer-events: none;
	}

	${(props) => {
		let style = defaultStyles;
		if (props.outlined) {
			style = outlinedStyled;
		}
		return css`
			${style}
		`;
	}}

	&:active,
	&:focus-visible,
	&:hover {
		background-color: ${(props) =>
			props.outlined ? 'rgba(230, 76, 101, 1)' : 'rgba(230, 76, 101, 0.5);'};
		outline: none;
	}
`;

interface IconProps {
	passedEvent(): void;
	outlined?: boolean;
	className?: string;
	disabled?: boolean;
}

const TrashCanIcon = ({
	passedEvent,
	outlined = true,
	className = '',
	disabled = false,
}: IconProps) => (
	<StyledIcon
		className={className}
		onClick={passedEvent}
		outlined={outlined}
		type="button"
		disabled={disabled}>
		<svg viewBox="0 0 112 112" fill="none">
			<path
				d="M98.97 15.785l-1.437-4.503a6.759 6.759 0 00-6.46-4.72h-19.77C71.528 3.03 68.858-.001 65.287 0H46.714c-3.546-.005-6.274 3.057-6.016 6.563h-19.77a6.759 6.759 0 00-6.46 4.719l-1.438 4.503a3.339 3.339 0 00.483 2.975 3.34 3.34 0 002.688 1.365h2.781l2.894 83.954A8.173 8.173 0 0030.074 112h51.853a8.173 8.173 0 008.197-7.921l.841-24.387a1.64 1.64 0 00-3.28-.113l-.84 24.386a4.904 4.904 0 01-4.918 4.754H76.84l3.164-88.594h9.73L87.94 71.868a1.64 1.64 0 003.28.113l1.798-51.856H95.8a3.34 3.34 0 002.687-1.365 3.339 3.339 0 00.484-2.975zM46.715 3.281h18.573c1.71-.013 2.973 1.597 2.734 3.282H43.98c-.234-1.702 1.004-3.282 2.735-3.282zM30.074 108.72a4.904 4.904 0 01-4.918-4.753l-2.89-83.841h9.73l3.163 88.594h-5.085zm65.764-91.894c-.014.019-.025.019-.038.019H50.07a1.64 1.64 0 000 3.281h26.652l-3.164 88.594H38.443l-3.164-88.594h7.091a1.64 1.64 0 000-3.281H16.201c-.013 0-.024 0-.038-.02-.014-.019-.01-.029-.007-.041l1.438-4.503a3.488 3.488 0 013.334-2.436h70.145c1.53 0 2.87.979 3.334 2.436l1.438 4.502c.004.013.007.023-.007.043z"
				fill="#484969"
			/>
		</svg>
		<span className="screen-reader-text">Delete Icon</span>
	</StyledIcon>
);

export default TrashCanIcon;
