import React, { useContext, useState, useRef } from 'react';
import dompurify from 'dompurify';

import GlobalContext from '@/context/GlobalContext';
import { ParentAlignmentStyle } from '@/Shared/StyledElements';
import { StyledH3, StyledP } from '@/Shared/Typography/typography';
import useOutsideClick from '@/hooks/useOutsideClick';
import { StyledBanner, StyledCourseAlignmentWrap } from '@/Pages/ParentMap/ParentMapStyles';
import CourseAlignment from '@/components/CourseAlignment/CourseAlignment';
import { sanitizeOptions } from '@/utils';
import PublicNavbar from '@/components/Navbar/PublicNavbar';

const MemoizedCourseAlignment = React.memo(CourseAlignment);

const ParentAlignment = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { siteSettings } = useContext(GlobalContext);
	const ref = useRef<HTMLDivElement>(document.createElement('div'));

	useOutsideClick(ref, () => {
		if (isOpen) {
			setIsOpen(false);
		}
	});

	return (
		<>
			<PublicNavbar isSearchable={false} />
			<StyledBanner id="alignment-banner" image={`${siteSettings?.banner ?? ''}`}>
				<div className="overlay" />
				<div className="wrap">
					<StyledH3>{siteSettings?.statement_title}</StyledH3>
					<StyledP
						dangerouslySetInnerHTML={{
							__html: dompurify.sanitize(`${siteSettings?.statement ?? ''}`, {
								...sanitizeOptions,
							}),
						}}
					/>
				</div>
			</StyledBanner>
			<ParentAlignmentStyle />
			<StyledCourseAlignmentWrap>
				<MemoizedCourseAlignment />
			</StyledCourseAlignmentWrap>
		</>
	);
};

export default ParentAlignment;
