import { useState, ReactElement } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

const StyledTabGroup = styled.div<{ fillWidthButton: boolean }>`
	${(props) => {
		if (props.fillWidthButton) {
			return `
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
			`;
		}
		return `
			display: flex;
			flex-wrap: wrap;
		`;
	}}
	margin-bottom: var(--spacing-2);
	position: relative;
	width: 100%;

	&:after {
		background-color: var(--tab-underline-color);
		bottom: 0;
		content: '';
		height: 2px;
		position: absolute;
		width: 100%;
	}

	button {
		background: none;
		border: none;
		border-radius: 4px 4px 0 0;
		color: var(--tab-text-color);
		cursor: pointer;
		padding: var(--spacing-2) var(--spacing-4) var(--spacing-2);
		position: relative;

		&:active,
		&:focus {
			background-color: var(--tab-focus-color);
			color: var(--tab-active-text-color);
			outline-color: var(--tab-focus-outline-color);

			&:after {
				background-color: var(--tab-active-underline-color);
				bottom: 0;
				content: '';
				height: 2px;
				left: 0;
				position: absolute;
				width: 100%;
				z-index: 1;
			}
		}

		&:hover {
			background-color: var(--tab-focus-color);
			color: var(--tab-active-text-color);

			&:after {
				background-color: var(--tab-active-underline-color);
				bottom: 0;
				content: '';
				height: 2px;
				left: 0;
				position: absolute;
				width: 100%;
				z-index: 1;
			}
		}

		&.active {
			color: var(--tab-active-text-color);

			&:after {
				background-color: var(--tab-active-underline-color);
				bottom: 0;
				content: '';
				height: 2px;
				left: 0;
				position: absolute;
				width: 100%;
				z-index: 1;
			}
		}
	}
`;

const StyledWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: auto;
	padding: var(--spacing-4) var(--spacing-2) var(--spacing-4);
	width: 100%;
`;

interface TabProps {
	selected: number;
	children: ReactElement[];
	fillWidthButton?: boolean;
}

const Tabs = ({ ...props }: TabProps) => {
	const { children, selected, fillWidthButton = false } = props;
	const [selectedIndex, setSelectedIndex] = useState(selected);
	const navigate = useNavigate();
	const location = useLocation();
	const handleChange = (index: number, title: string) => {
		setSelectedIndex(index);
		if (location.pathname.includes('submissions')) return;
		// regex replace spaces with dashes
		navigate(`#${title.replace(/\s/g, '-').toLowerCase()}`, { replace: true });
	};
	const title = (elm: ReactElement) => elm.props.title ?? elm.props.children.props.title;
	return (
		<>
			<StyledTabGroup
				role="tablist"
				aria-label="Course Tabs"
				fillWidthButton={fillWidthButton}>
				{children?.map((elm, index) => {
					// return null for conditional tabs
					if (!elm.props.children) return null;

					const isActive = index === selectedIndex;
					return (
						<button
							className={isActive ? 'active' : ''}
							type="button"
							aria-selected={isActive}
							aria-controls={`panel-${index}`}
							id={`course-tab-${index}`}
							key={`${index.toString()}`}
							role="tab"
							onClick={() => handleChange(index, title(elm))}>
							{title(elm)}
						</button>
					);
				})}
			</StyledTabGroup>
			<StyledWrapper role="tabpanel" id={`panel-${selectedIndex}`}>
				{children[selectedIndex]}
			</StyledWrapper>
		</>
	);
};

export default Tabs;
