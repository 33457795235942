import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import elevateLogo from '@/assets/logo.svg';
import { media } from '@/Shared/theme';

const StyledLogo = styled.img<{ hasImage: boolean }>`
	padding: 20px 0 10px;
	max-height: 70px;
	max-width: ${(props) => (props.hasImage ? '200px' : '120px')};

	${media.smallScreen} {
		max-height: 50px;
		padding: 0;
	}
`;

interface LogoProps {
	logoUrl: string;
}

const Logo = ({ logoUrl }: LogoProps) => {
	const src = logoUrl === '' ? elevateLogo : logoUrl;

	return (
		<Link to="/" className="logo-link">
			<StyledLogo
				src={`${src}`}
				hasImage={logoUrl !== ''}
				alt="school logo"
				className="logo"
			/>
		</Link>
	);
};

export default Logo;
