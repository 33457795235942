import { useEffect } from 'react';

const useOutsideClick = (ref: RefElement, callback: () => void) => {
	useEffect(() => {
		const handleClick = (e: MouseEvent) => {
			if (!ref.current || ref.current.contains(e.target as Node)) {
				return;
			}
			callback();
		};
		document.addEventListener('click', handleClick, true);

		return () => {
			document.removeEventListener('click', handleClick, true);
		};
	}, [ref, callback]);
};

export default useOutsideClick;
