import { Fragment } from 'react';
import { hierarchy as d3Hierarchy } from 'd3-hierarchy';
import { group as d3Group } from 'd3-array';

import { NodeEntity } from '@/components/CourseMap/coursemap.types';
import { Details } from '@/stores/alignmentStore';
import { StyledCardDeck, StyledWrapper, StyledListCard } from '../CourseAlignmentStyles';
import Accordion from './Accordion/Accordion';
import CourseCard from './CourseCard/CourseCard';
import DisciplineCard from './DisciplineCard';

interface CardDeckProps {
	course: any;
	selectedDiscipline: Discipline[];
	coursesArray: any;
	selectedDivision: Division[];
	isAP: boolean;
	details: Details;
}

const CardDeck = ({ ...props }: CardDeckProps) => {
	const { coursesArray, selectedDivision, selectedDiscipline, course, isAP, details } = props;
	const nodes: any = d3Hierarchy(course);
	const group: any = d3Group(nodes.descendants(), (d: any) => d.depth);
	const setClassName = (c: any) => (c.data.name.includes('Spacer') ? 'spacer' : '').toString();

	return (
		<StyledCardDeck className={`${course.name?.replace(/\s/g, '-')}-row deck`}>
			<StyledWrapper>
				{Array.from(group).map((g: any, i: number) => {
					if (g[1].length > 1) {
						const childrenWithoutSpacer = g[1].filter(
							(x: NodeEntity) => !x.data.name.includes('Spacer')
						);

						return (
							<Fragment key={`${course.id}-${course.tracker}-${i.toString()}`}>
								<StyledListCard>
									{childrenWithoutSpacer.map((c: any) => {
										const courseData = coursesArray?.find(
											(a: any) => a.course_id === c.data.tracker
										);

										const isVisible =
											selectedDivision.find((d) => d.name === c.data.division)
												?.isSelected && !c.data.isArchived;

										const isApOnly = /AP/.test(c.data.name);

										if (isAP) {
											return (
												isApOnly && (
													<Fragment key={c.data.tracker}>
														<Accordion
															courseData={courseData}
															title={c.data.name}
															details={details}
														/>
													</Fragment>
												)
											);
										}

										if (
											!selectedDiscipline.find(
												(d) => d.name === c.data.discipline
											)?.isSelected
										) {
											return null;
										}

										return (
											<Fragment key={c.data.tracker}>
												{isVisible && (
													<Accordion
														courseData={courseData}
														title={c.data.name}
														details={details}
													/>
												)}
											</Fragment>
										);
									})}
								</StyledListCard>
							</Fragment>
						);
					}

					const childrenWithoutSpacer = g[1].filter(
						(x: NodeEntity) => !x.data.name.includes('Spacer')
					);

					return childrenWithoutSpacer?.map((c: any) => {
						if (
							!selectedDiscipline.find((d) => d.name === c.data.discipline)
								?.isSelected
						) {
							return null;
						}

						if (c.data?.isDiscipline) {
							return (
								<DisciplineCard
									name={c.data.name}
									key={c.data.tracker}
									color={c.data.color}
								/>
							);
						}

						const isApOnly = c.data.isAp;

						if (isAP) {
							return (
								isApOnly && (
									<Fragment key={c.data.tracker}>
										<CourseCard
											courseData={coursesArray?.find(
												(a: any) => a.course_id === c.data.tracker
											)}
											className={setClassName(c)}
											data-id={g.depth}
											courseName={c.data.name}
											courseDivision={c.data.division}
											details={details}
										/>
									</Fragment>
								)
							);
						}

						const isNotVisible = selectedDivision.some(
							(d) =>
								(d.name === c.data.division && !d.isSelected) || c.data.isArchived
						);
						if (isNotVisible) {
							return null;
						}

						return (
							<Fragment key={c.data.tracker}>
								<CourseCard
									courseData={coursesArray?.find(
										(a: any) => a.course_id === c.data.tracker
									)}
									className={setClassName(c)}
									data-id={g.depth}
									courseName={c.data.name}
									courseDivision={c.data.division}
									details={details}
								/>
							</Fragment>
						);
					});
				})}
			</StyledWrapper>
		</StyledCardDeck>
	);
};

export default CardDeck;
