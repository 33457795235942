/* eslint-disable import/no-extraneous-dependencies */
import { showErrorToast } from '@/components/ToastNotification';
import { Node, nodePasteRule } from '@tiptap/core';
import { Plugin } from 'prosemirror-state';
import { Flip } from 'react-toastify';

interface VideoOptions {
	HTMLAttributes: Record<string, any>;
	allow: string;
	addPasteHandler: boolean;
	allowFullscreen: boolean;
	autoplay: boolean;
	ccLanguage?: string;
	ccLoadPolicy?: boolean;
	controls: boolean;
	disableKBcontrols: boolean;
	enableIFrameApi: boolean;
	endTime: number;
	height: number;
	interfaceLanguage?: string;
	ivLoadPolicy: number;
	loop: boolean;
	title: string;
	modestBranding: boolean;
	inline: boolean;
	nocookie: boolean;
	origin: string;
	playlist: string;
	progressBarColor?: string;
	width: number;
}

const getYoutubeEmbedUrl = (nocookie?: boolean) => {
	return nocookie ? 'https://www.youtube-nocookie.com/embed/' : 'https://www.youtube.com/embed/';
};

const getIdWithParams = (url: string, id: string) => {
	// Extract URL parameters
	const urlParamsMatch = url.match(/(\?|&|#)([^=]+)=([^&]+)/g) || [];
	// Join URL parameters
	const urlParams = urlParamsMatch.map((param) => param).join('');

	return `${id}${urlParams}`;
};

const showInvalidVideoIdToast = (id: RegExpMatchArray | null) => {
	if (id?.[1]) return false;

	showErrorToast('Sorry, the video ID is invalid.', {
		position: 'bottom-center',
		theme: 'colored',
		transition: Flip,
		autoClose: 3000,
	});
	return true;
};

declare module '@tiptap/core' {
	interface Commands<ReturnType> {
		embed: {
			/**
			 * Set a video mark
			 */
			setVideo: (value?: string) => ReturnType;
		};
	}
}

export const Video = Node.create<VideoOptions>({
	name: 'video',
	group: 'block',
	selectable: true,
	draggable: true,
	atom: true,

	addOptions() {
		return {
			addPasteHandler: true,
			allow: '',
			allowFullscreen: true,
			autoplay: false,
			ccLanguage: undefined,
			ccLoadPolicy: undefined,
			controls: true,
			disableKBcontrols: false,
			enableIFrameApi: false,
			endTime: 0,
			height: 480,
			interfaceLanguage: undefined,
			ivLoadPolicy: 0,
			loop: false,
			title: 'Embedded video',
			modestBranding: false,
			HTMLAttributes: {},
			inline: false,
			nocookie: false,
			origin: '',
			playlist: '',
			progressBarColor: undefined,
			width: 640,
		};
	},

	addAttributes() {
		return {
			allowfullscreen: {
				default: this.options.allowFullscreen,
			},
			allow: {
				default: this.options.allow,
			},
			height: {
				default: this.options.height,
			},
			width: {
				default: this.options.width,
			},
			title: {
				default: 'Embedded video',
			},
			src: {
				parseHTML: (el) => (el as any).getAttribute('src'),
				renderHTML: (attrs) => {
					if (attrs.src) {
						return { src: attrs.src };
					}

					return {};
				},
			},
		};
	},

	addCommands() {
		return {
			setVideo:
				(initialValue: any) =>
				({ commands }: any) => {
					// Create a temporary element
					const temp = document.createElement('div');
					// Set the HTML content with the initial value
					temp.innerHTML = initialValue;
					// Get the attributes of the first child
					const elementAttr = temp.firstElementChild?.attributes;

					// temporary object to store the attributes
					let attributesObj;

					if (elementAttr) {
						// Convert the NamedNodeMap to an array and remove the src attribute
						const arrayNodeMap = Object.keys(elementAttr)
							.map((index) => elementAttr[index as keyof NamedNodeMap] as Attr)
							.filter((value) => value.localName !== 'src');
						// Convert the array to an object with the localName as key and nodeValue as value
						attributesObj = Object.fromEntries(
							arrayNodeMap.map((value) => {
								return [value.localName, value.nodeValue];
							})
						);
					}

					const video = initialValue;

					if (video === '') {
						// Handle empty input
						return false;
					}

					// Regular expression to extract the src attribute
					const regex = /<iframe[^>]*\s+src\s*=\s*["']?([^"'>]+)["']?/;
					const match = video.match(regex);

					let srcValue = video;
					// Use the regex to find the src attribute value if the video is an iframe
					if (match) {
						srcValue = match[1];
					}

					if (!srcValue?.match(/youtube|vimeo|youtu.be|loom/)) {
						// Check if the video must be hosted on YouTube or Vimeo
						showErrorToast('Sorry...only YouTube, Vimeo & Loom videos are supported.', {
							position: 'bottom-center',
							theme: 'colored',
							transition: Flip,
							autoClose: 3000,
						});
						return false;
					}

					// Handle loom.com URLs
					if (srcValue?.match(/loom/)) {
						// Extract YouTube video ID
						const idMatch = srcValue.match(/\/(?:embed|share)\/([A-Za-z0-9-]+)/);
						const id = idMatch?.[1];

						// Check if the video ID is valid
						if (showInvalidVideoIdToast(idMatch)) return false;

						srcValue = `https://loom.com/embed/${getIdWithParams(srcValue, id)}`;
					}

					// Handle youtube.com and youtu.be URLs
					if (srcValue?.match(/youtube|youtu.be/)) {
						// Extract YouTube video ID
						const idMatch = srcValue.match(/(?:v=|\/)([A-Za-z0-9_-]{11})/);
						const id = idMatch?.[1];
						// Check if the video ID is valid
						if (showInvalidVideoIdToast(idMatch)) return false;

						// add origin to src to allow iframe to load and prevent CORS errors
						// always use nocookie embed URL to prevent cookies from being
						// set and to improve performance. Some browsers block third-party
						srcValue = `${getYoutubeEmbedUrl(true)}${getIdWithParams(
							srcValue,
							id
						)}&origin=${window.location.origin}`;
					}

					// Handle vimeo.com URLs
					if (srcValue?.match(/vimeo/)) {
						const idMatch = srcValue.match(/\/(?:video\/|channels\/\w+\/)?(\d+)/);
						const id = idMatch?.[1];

						// Check if the video ID is valid
						if (showInvalidVideoIdToast(idMatch)) return false;

						srcValue = `https://player.vimeo.com/video/${getIdWithParams(
							srcValue,
							id
						)}`;
					}

					return commands.insertContent({
						type: this.name,
						attrs: {
							src: srcValue,
							...attributesObj,
						},
					});
				},
		};
	},

	addPasteRules() {
		const pasteRegex = /(?:^|\s)((?:~)((?:[^~]+))(?:~))/g;

		return [
			nodePasteRule({
				find: pasteRegex,
				type: this.type,
			}),
		];
	},

	parseHTML() {
		return [
			{
				tag: 'iframe',
			},
		];
	},

	addProseMirrorPlugins() {
		return [
			new Plugin({
				props: {
					handlePaste: (_, event) => {
						const pasteData = event.clipboardData?.getData('text/plain');

						if (!pasteData?.match(/youtube|vimeo|youtu.be|loom/) || !pasteData) {
							return false;
						}

						return this.editor.commands.setVideo(
							event.clipboardData?.getData('text/plain')
						);
					},
				},
			}),
		];
	},

	renderHTML({ HTMLAttributes }) {
		return ['div', this.options.HTMLAttributes, ['iframe', HTMLAttributes]];
	},

	addNodeView() {
		return ({ editor, node }) => {
			const div = document.createElement('div');
			div.className = `${editor.isEditable ? ' cursor-pointer' : ''}`;
			const iframe = document.createElement('iframe');
			if (editor.isEditable) {
				iframe.className = 'pointer-events-none';
			}
			iframe.allow =
				node.attrs.allow ||
				'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';
			iframe.allowFullscreen = node.attrs.allowFullscreen || true;
			iframe.width = `${node.attrs.width}` || `${this.options.width}`;
			iframe.height = `${node.attrs.height}` || `${this.options.height}`;
			iframe.title = node.attrs.title || this.options.title;
			iframe.src = node.attrs.src;
			div.append(iframe);
			return {
				dom: div,
			};
		};
	},
});
