import { media } from '@/Shared/theme';
import styled from 'styled-components';

export const StyledAvatar = styled.button`
	border-radius: 50%;
	border: 4px solid transparent;
	cursor: pointer;
	height: 40px;
	margin-left: var(--spacing-8);
	overflow: hidden;
	padding: 0;
	transition: 0.3s border-color;
	width: 40px;

	${media.smallScreen} {
		height: 50px;
		width: 50px;
	}

	&:focus,
	&:hover {
		border: 4px solid rgb(115 114 149 / 43%);
		outline: none;
	}

	img {
		object-fit: cover;
	}

	div {
		align-items: center;
		background-color: #737295;
		color: #fff;
		display: flex;
		font-family: var(--font-semibold);
		height: 100%;
		justify-content: center;
		line-height: 0;
		user-select: none;
	}
`;

export const StyledOptions = styled.div`
	position: relative;
`;

export const StyleOptionsDrawer = styled.div`
	align-items: center;
	bottom: -50px;
	display: flex;
	height: 100%;
	justify-content: flex-end;
	position: absolute;
	right: 0;
	width: 125px;

	button {
		background-color: var(--neutral-50);
		border-radius: 6px;
		border: 1px solid var(--elevate-primary);
		color: var(--label-text);
		cursor: pointer;
		margin-top: 1px;
		padding: var(--spacing-1) var(--spacing-3);

		&:focus,
		&:hover {
			background-color: var(--elevate-primary);
			color: var(--neutral-50);
		}
	}
`;

export const StyledNavbar = styled.nav`
	align-items: flex-start;
	background-color: var(--neutral-50);
	box-shadow: 0 5px 8px 1px rgba(0, 0, 0, 0.1);
	display: flex;
	padding: var(--spacing-4) var(--spacing-6) var(--spacing-6);
	position: relative;
	z-index: 1;
	flex-direction: column;
	z-index: 2;

	${media.condensed} {
		flex-direction: row;
		align-items: center;
		padding-bottom: var(--spacing-4);
	}

	.logo-link {
		margin: auto;

		${media.smallScreen} {
			margin: auto auto var(--spacing-4);
		}

		${media.condensed} {
			margin: 0 auto 0 0;
		}
	}

	.navigation {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: auto;

		${media.condensed} {
			margin: 0;
		}

		${StyledOptions} {
			position: absolute;
			right: 16px;
			top: 16px;

			${media.condensed} {
				margin-left: var(--spacing-4);
				position: static;
			}
		}

		.course-map-link {
			display: none;

			${media.smallScreen} {
				display: inline-block;
			}
		}

		.navigation-link,
		.sign-in-link {
			color: var(--org-color);
			font-family: var(--font-semibold);
			margin: 0 var(--spacing-2);
			text-decoration: none;
			padding: 4px var(--spacing-2);

			&:focus,
			&:hover {
				box-shadow: 0px 2px 0px 0px var(--org-color);
			}
		}
	}

	.help-btn {
		align-items: center;
		background: none;
		border: none;
		color: var(--org-color);
		cursor: pointer;
		display: flex;
		font-family: var(--font-semibold);

		${media.condensed} {
			margin-right: var(--spacing-6);
		}

		&:focus,
		&:hover {
			outline: none;
			box-shadow: 0px 2px 0px 0px var(--org-color);
		}

		span {
			margin-left: var(--spacing-2);
		}
	}
`;
