import styled from 'styled-components';
import { StyledButton, StyledSearch, StyledPrimaryButton } from '@/Shared/StyledElements';
import { CloseIcon } from '@/icons/index';

export const StyledCourseList = styled.div`
	background-color: var(--panel-color);
	bottom: 0;
	box-shadow: -4px 0px 8px rgb(0 0 0 / 15%);
	display: flex;
	flex-direction: column;
	padding: var(--spacing-4) var(--spacing-3);
	position: absolute;
	right: 0;
	top: 0;
	width: 400px;

	.selection-container {
		background-color: var(--neutral-50);
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		width: 100%;

		&--archive {
			> div:first-of-type {
				margin-top: var(--spacing-4);
			}

			.course-button:hover {
				background-color: #f5f5f5;
			}
		}
	}

	.discipline-container {
		padding: var(--spacing-4) 0;

		.course-button {
			width: 100%;
		}
	}

	${StyledSearch} {
		margin: 0;
	}

	.no-matches-text {
		background-color: var(--neutral-50);
		color: var(--body-text);
		display: inline-block;
		padding: var(--spacing-2) var(--spacing-4);
	}

	${StyledPrimaryButton} {
		margin-top: var(--spacing-4);
	}

	.accordion-body {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: max-height 0.3s ease, opacity 0.1s linear;
	}

	.accordion-body--active {
		max-height: 600px;
		opacity: 1;
		overflow: visible;
	}

	.spacer-button {
		background-color: #f5f5f5;
		border-radius: 6px;
		grid-column: 1/4;
		margin-bottom: var(--spacing-2);
		margin-top: var(--spacing-4);
	}

	.course-button {
		border: none;
		padding: var(--spacing-2) var(--spacing-4);
		text-align: left;

		&:hover {
			background-color: var(--grey-focus-bg);
		}

		&:focus {
			background-color: var(--grey-active-bg);
			outline: none;
		}

		&[disabled] {
			color: inherit;
			pointer-events: none;
		}
	}
`;

export const StyledListItem = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: var(--spacing-4);
	padding-right: var(--spacing-4);
	position: relative;

	.item-row {
		flex-direction: column;
		margin-left: var(--spacing-4);
		width: 100%;
	}

	.button-group {
		align-items: center;
		display: grid;
		gap: var(--spacing-4);
		grid-template-columns: 1fr auto auto;
		width: 100%;
	}

	.course-button {
		background-color: #f5f5f5;
		border-radius: 6px;
		width: 100%;
	}
`;

export const StyledEditForm = styled.div`
	background: var(--purple-50);
	border-radius: 6px;
	margin-bottom: var(--spacing-6);
	margin-top: var(--spacing-2);
	padding: var(--spacing-4);
	position: absolute;
	right: 407px;
	top: 0;
	width: 100%;
	z-index: 10;

	.list {
		position: sticky;
		top: 0;
	}

	.course-division,
	.course-discipline {
		margin-bottom: var(--spacing-2);
	}

	.course-grade {
		margin-bottom: var(--spacing-4);
	}

	${StyledButton} {
		margin-bottom: var(--spacing-6);
		margin-top: 0;
	}

	.input-container {
		margin-bottom: var(--spacing-2);
		max-width: none;
		width: 100%;
	}

	label,
	.label {
		color: var(--body-text);
		font-size: 1.2rem;
	}

	.success-message {
		color: var(--success-message);
		font-family: var(--font-semibold);
		position: absolute;
		top: -12px;
	}
`;

export const StyledCloseIcon = styled(CloseIcon as any)`
	background-color: var(--tab-group-color);
	left: -48px;
	right: auto;
	z-index: 1;

	&.is-new-course {
		left: auto;
		right: 5px;
		top: 8px;
	}
`;
