import styled from 'styled-components';

export const StyledMap = styled.div`
	display: flex;
	flex-direction: column;
	height: calc(100vh - 92px);
	width: 100%;

	.course-map {
		&.dragging {
			cursor: all-scroll;
		}
	}

	.link {
		fill: none;
		stroke-width: 2px;
	}

	text {
		pointer-events: none;
	}

	.empty-icon {
		fill: none;
		stroke: none;
		pointer-events: none;
	}

	.empty-icon + path + path {
		display: none;
	}

	.map-merge-icon {
		fill: #737295;
		stroke: #737295;
	}

	.map-plus-icon,
	.map-minus-icon,
	.map-merge-icon {
		cursor: pointer;
	}

	.map-plus-icon {
		fill: var(--green-500);
		stroke: var(--c-white);
		stroke-width: 3px;
	}

	.map-minus-icon {
		fill: var(--red-500);
		stroke: var(--c-white);
		stroke-width: 3px;
	}

	.active-icon {
		animation: pulse 1s infinite;
	}

	@keyframes pulse {
		0% {
			fill: var(--green-500);
			stroke: var(--green-500);
			stroke-width: 0;
		}
		50% {
			fill: var(--green-400);
			stroke: var(--green-400);
			stroke-width: 7px;
		}
		100% {
			fill: var(--green-500);
			stroke: var(--green-500);
			stroke-width: 0;
		}
	}

	.base-node-icon {
		display: none;
		pointer-events: none;
	}

	.new-rect {
		fill: var(--org-color);
		stroke: var(--org-color);
	}

	rect {
		transition: fill 0.3s, stroke 0.3s;
	}

	.new-rect + text {
		fill: var(--neutral-50);
	}

	.menu-btn {
		position: absolute;
		right: var(--spacing-8);
		top: var(--spacing-8);
	}

	@keyframes dashoffset-animation {
		to {
			stroke-dashoffset: 1000;
		}
	}

	.course-backdrop {
		opacity: 0;
		stroke-dasharray: 50;
		stroke-width: 3px;
		stroke: var(--org-color);
		transition: opacity 0.3s;
	}

	.active-node {
		animation: dashoffset-animation 4s infinite linear;
		opacity: 1;
	}

	.discipline-card {
		cursor: pointer;
	}

	.view-button {
		cursor: pointer;

		.panel & {
			opacity: 0.3;
			cursor: none;
			pointer-events: none;
		}
	}

	.view-button-text {
		.panel & {
			cursor: none;
			pointer-events: none;
		}
	}
`;

export const StyledNotification = styled.div`
	background-color: var(--warning-bg-color);
	border-radius: 6px;
	bottom: var(--spacing-4);
	color: var(--warning-text-color);
	display: flex;
	left: 50%;
	padding: 4px var(--spacing-4);
	position: absolute;
	top: auto;
	transform: translateX(-50%);

	svg {
		fill: var(--warning-text-color);
		margin-right: var(--spacing-2);
	}
`;

export const StyledActions = styled.div`
	align-items: flex-start;
	background-color: var(--panel-color);
	border-radius: 6px;
	box-shadow: 0px 1px 8px rgb(0 0 0 / 15%);
	display: inline-flex;
	flex-direction: column;
	left: 10px;
	padding: var(--spacing-4);
	position: absolute;
	top: 10px;

	.save-btn {
		margin-bottom: var(--spacing-4);
	}
`;
