import { StyledInput } from '@/Shared/StyledElements';
import { PasswordIcon } from '@/icons';
import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import styled from 'styled-components';
import { FormErrorMessage } from '@/components/FormErrorMessage';

const StyledPassword = styled.div`
	position: relative;
	margin-bottom: var(--spacing-4);

	div {
		margin-bottom: 0;
	}

	input {
		padding-right: 60px;
	}

	div.error-message {
		margin-bottom: 0;
	}

	button {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		height: 42px;
		width: auto;
	}
`;

interface PasswordInputProps {
	registerOptions: UseFormRegisterReturn;
	errors?: any;
	labelText?: string;
}

const PasswordInput = ({ registerOptions, errors, labelText = 'Password' }: PasswordInputProps) => {
	const [isPasswordVisible, setPasswordVisible] = useState(false);

	const togglePassword = () => {
		setPasswordVisible(!isPasswordVisible);
	};

	const key = registerOptions.name;

	return (
		<>
			<label htmlFor={key}>{labelText}</label>
			<StyledPassword>
				<StyledInput
					data-testid={key}
					id={key}
					type={isPasswordVisible ? 'text' : 'password'}
					{...registerOptions}
				/>
				<PasswordIcon isPasswordVisible={isPasswordVisible} passedEvent={togglePassword} />
				<FormErrorMessage
					className="error-message"
					isShowing={!!errors?.[key]}
					message={errors[key]?.message}
				/>
			</StyledPassword>
		</>
	);
};

export default PasswordInput;
