import styled from 'styled-components';

export const StyledResource = styled.div`
	align-items: center;
	border-radius: 4px;
	display: flex;
	padding: var(--spacing-4) 0;
	margin-bottom: var(--spacing-4);
	position: relative;

	.input-wrap {
		border-bottom: 1px solid rgb(72 73 105 / 20%);
		margin-right: var(--spacing-4);
		padding-bottom: var(--spacing-4);
		width: 100%;

		div {
			flex: auto;
			max-width: none;
			width: 100%;
		}
	}

	input,
	textarea {
		margin-bottom: var(--spacing-4);
	}

	textarea {
		resize: vertical;
	}

	> button:last-of-type {
		margin-bottom: var(--spacing-4);
	}
`;

export const StyledResourceGroup = styled.div`
	& ${StyledResource}:nth-last-child(1) .input-wrap {
		border-bottom: none;
		padding-bottom: 0;
	}
`;

export const StyledActionGroup = styled.div`
	button:not(:last-child) {
		margin-right: var(--spacing-4);
	}
`;

export const StyledForm = styled.form`
	display: flex;
	flex-flow: column;
	width: 100%;
`;
