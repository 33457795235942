import styled from 'styled-components';
import { media } from '@/Shared/theme';

const StyledIcon = styled.div`
	align-items: center;
	color: var(--label-text);
	cursor: pointer;
	display: flex;

	svg {
		transition: color 0.15s ease-in;
	}

	span {
		display: none;
		margin-left: var(--spacing-2);
		${media.condensed} {
			display: inline;
		}
	}
`;

function StatsIcon() {
	return (
		<StyledIcon>
			<svg viewBox="0 0 113 113" fill="none" width="35" xmlns="http://www.w3.org/2000/svg">
				<g fill="currentColor">
					<path d="M112.516 58.793a1.786 1.786 0 00-1.304-.565h-26.24c-.904 0-1.665.675-1.774 1.572a26.748 26.748 0 01-5.397 13.148 1.787 1.787 0 00.15 2.357l18.518 18.518a1.788 1.788 0 002.606-.085c8.299-9.453 13.113-21.074 13.921-33.608a1.785 1.785 0 00-.48-1.337zm-14.892 31.13L81.56 73.862a30.33 30.33 0 004.953-12.059h22.759c-1.036 10.382-5.034 20.033-11.65 28.122zm15.372-37.171C111.175 24.502 88.499 1.825 60.248.004a1.786 1.786 0 00-1.902 1.783V28.15c0 .9.672 1.661 1.566 1.773a26.784 26.784 0 0123.262 23.171c.114.892.873 1.56 1.772 1.56h26.266a1.787 1.787 0 001.784-1.902zM86.478 51.08A30.359 30.359 0 0061.92 26.613V3.728c24.944 2.51 44.842 22.408 47.351 47.352H86.478zM54.207.484a1.775 1.775 0 00-1.337-.48C40.54.8 29.065 5.485 19.684 13.558a1.787 1.787 0 102.33 2.709c8.304-7.145 18.359-11.458 29.184-12.54v22.887c-9.88 1.768-18.401 8.43-22.48 17.689a1.787 1.787 0 103.27 1.44c3.79-8.6 11.919-14.661 21.218-15.82a1.787 1.787 0 001.566-1.773V1.788c0-.494-.204-.966-.565-1.304zM35.318 72.948c-3.66-4.733-5.594-10.403-5.594-16.398 0-1.525.13-3.054.383-4.545a1.787 1.787 0 00-3.523-.6 30.708 30.708 0 00-.434 5.145c0 6.265 1.864 12.216 5.407 17.312L15.496 89.923A52.93 52.93 0 013.574 56.441a52.893 52.893 0 0113.429-35.25 1.787 1.787 0 10-2.667-2.38A56.46 56.46 0 000 56.442a56.503 56.503 0 0014.044 37.297 1.787 1.787 0 002.606.085l18.518-18.518c.636-.636.7-1.645.15-2.357zM93.941 96.35L75.438 77.847a1.788 1.788 0 00-2.365-.144c-4.754 3.717-10.465 5.682-16.514 5.682-6.05 0-11.76-1.965-16.514-5.682a1.788 1.788 0 00-2.365.144L19.177 96.35a1.787 1.787 0 00.085 2.606A56.501 56.501 0 0056.558 113a56.506 56.506 0 0037.297-14.044 1.787 1.787 0 00.085-2.606zM56.56 109.426a52.932 52.932 0 01-33.482-11.922l16.04-16.04c5.123 3.6 11.12 5.495 17.442 5.495 6.323 0 12.32-1.894 17.441-5.495l16.04 16.04a52.93 52.93 0 01-33.48 11.922z" />
				</g>
			</svg>
			<span>Stats</span>
		</StyledIcon>
	);
}

export default StatsIcon;
