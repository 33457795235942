import styled from 'styled-components';
import { StyledCheckboxLabel, StyledPrimaryButton } from '@/Shared/StyledElements';
import { CloseIcon } from '@/icons/index';

export const StyledActionsContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;

	button:not(:last-child) {
		margin-right: var(--spacing-4);
	}
`;

export const StyledUtilsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${StyledPrimaryButton} {
		align-self: flex-start;
	}
`;

export const modalStyles: ReactModal.Styles = {
	content: {
		backgroundColor: 'var(--modal-bg)',
		border: 'none',
		inset: 'initial',
		margin: 'auto',
		maxHeight: '800px',
		maxWidth: '900px',
		overflow: 'hidden auto',
		position: 'relative',
		width: '95%',
	},
	overlay: {
		backgroundColor: 'var(--modal-overlay)',
		display: 'flex',
		justifyContent: 'center',
		zIndex: 1,
	},
};

export const StyledModalBody = styled.div`
	display: flex;
	flex-wrap: wrap;

	form {
		display: grid;
		grid-column-gap: var(--spacing-6);
		grid-template-columns: 1fr 1fr;
		width: 100%;
	}

	.tag-preview-container {
		grid-column: 1/3;
		margin-top: 4px;
	}

	.tag-preview {
		align-items: center;
		display: flex;
		gap: var(--spacing-8);
	}

	.input-container {
		max-width: none;
		width: 100%;
	}

	label {
		color: var(--body-text);
		font-size: 1.2rem;
	}

	.tag-definition {
		grid-column: 1/3;
		margin-right: 0;

		.textarea {
			resize: vertical;
			max-width: none;
		}
	}

	hr {
		grid-column: 1/3;
	}
`;

export const StyledForm = styled.form`
	display: flex;
	flex-flow: row wrap;
`;

export const StyledFooterBody = styled.div`
	text-align: right;
`;

export const StyledModalHeader = styled.div`
	box-shadow: 0px 2px 0px -1px rgb(50 50 50 / 10%);
	margin-bottom: var(--spacing-8);
	padding-bottom: var(--spacing-4);
`;

export const StyledCheckboxContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	grid-column: 1/3;
	width: 100%;

	h5 {
		flex: 0 0 100%;
	}

	p {
		font-size: 1.3rem;
		width: 100%;
		font-style: italic;
	}

	${StyledCheckboxLabel} {
		font-size: unset;
		margin-bottom: var(--spacing-4);
		width: 50%;
	}
`;

export const StyledCloseIcon = styled(CloseIcon as any)`
	background-color: var(--tab-group-color);
`;
