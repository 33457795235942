import styled from 'styled-components';
import { StyledPrimaryButton } from '@/Shared/StyledElements';

export const StyledSettings = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-y: auto;

	${StyledPrimaryButton} {
		margin-left: auto;
	}

	input {
		max-width: 300px;
	}

	.banner-preview {
		background-position: center;
		background-size: cover;
		margin-bottom: var(--spacing-4);
		min-height: 200px;
	}

	.placeholder-image {
		margin-bottom: var(--spacing-4);
	}

	.logo-preview {
		background-color: #d9d9d9;
		margin-bottom: var(--spacing-4);
		max-width: 300px;
	}
`;
