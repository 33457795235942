import styled from 'styled-components';

export const StyledButtonGroup = styled.div`
	div {
		color: var(--button-group-text);
		font-family: var(--font-regular);
		padding-bottom: var(--spacing-2);
	}

	.button-group {
		background-color: var(--button-group-bg);
		border-radius: 6px;
		margin-bottom: var(--spacing-4);
		padding: var(--spacing-1);
	}

	button {
		background-color: transparent;
		border-radius: 4px;
		border: none;
		color: var(--button-group-text);
		cursor: pointer;
		font-family: var(--font-semibold);
		font-size: 1.5rem;
		margin-right: var(--spacing-2);
		padding: var(--spacing-1) var(--spacing-4);
		transition: background-color 0.3s, color 0.3s;

		&:last-of-type {
			margin-right: 0;
		}

		&.active {
			background-color: var(--button-group-active);
			color: var(--button-group-text-active);
		}

		&:hover {
			background-color: var(--button-group-hover-bg);
			color: var(--button-group-text);
		}

		&:focus {
			outline: 1px solid var(--button-group-outline);
		}
	}
`;
