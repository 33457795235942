import styled from 'styled-components';

export const StyledLessonPlans = styled.div`
	width: 100%;

	.editor-wrapper {
		margin-bottom: var(--spacing-4);
		padding-bottom: var(--spacing-4);
	}

	.more-option-wrapper {
		margin-bottom: var(--spacing-4);
	}
`;

export const StyledLessonPlanFooter = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

export const StyledLessonsPlaceholder = styled.div`
	color: var(--body-text);
	padding: var(--spacing-4);
	width: 100%;
`;

export const StyledLessonPlanPanel = styled.div`
	background-color: var(--comment-placeholder-color);
	border-radius: 6px;
	color: var(--body-text);
	height: 100%;
	padding: var(--spacing-4);
	width: 100%;

	.lesson-plan-group {
		display: flex;
		flex-direction: column;

		&.lesson-plan-group-active .lesson-plan-list {
			display: block;
		}
	}

	.lesson-plan-list {
		display: none;
		margin-left: var(--spacing-4);
	}

	.lesson-plan-group-active > .lesson-plan-item .lesson-plan-button {
		background-color: var(--wine-btn-active);
		color: var(--neutral-50);
		font-weight: bold;
	}

	.lesson-plan-item {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: var(--spacing-4);
	}

	.lesson-plan-button {
		background-color: var(--neutral-50);
		border-radius: 4px;
		border: 1px solid var(--elevate-primary);
		color: var(--elevate-primary);
		font-size: 1.4rem;
		margin-right: 16px;
		overflow: hidden;
		padding: 8px 12px;
		text-align: left;
		transition: background-color 0.3s, color 0.3s;
		width: 100%;
		word-break: break-all;

		&:focus-visible,
		&:hover {
			background-color: var(--wine-btn-active);
			border-color: var(--wine-btn-active);
			box-shadow: none;
			color: var(--neutral-50);
		}
	}
	.lesson-plan-item-active {
		border-color: var(--elevate-secondary);
		box-shadow: inset 0px 0px 1px 1px var(--elevate-secondary);
	}

	button {
	}
`;

export const StyledLessonPlanForm = styled.form`
	margin-bottom: var(--spacing-6);

	input {
		margin-bottom: var(--spacing-2);
	}

	.error-message {
		margin-bottom: var(--spacing-3);
		margin-top: calc(var(--spacing-3) * -1);
	}

	button {
	}
`;
