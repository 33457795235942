import { v4 as uuidv4 } from 'uuid';

export const courseGrades = [
	{
		value: '',
		label: '-',
		id: uuidv4(),
	},
	{
		value: 'JK',
		label: 'JK',
		id: uuidv4(),
	},
	{
		value: 'SK',
		label: 'SK',
		id: uuidv4(),
	},
	{
		value: 'K',
		label: 'K',
		id: uuidv4(),
	},
	{
		value: '1st',
		label: '1st',
		id: uuidv4(),
	},
	{
		value: '2nd',
		label: '2nd',
		id: uuidv4(),
	},
	{
		value: '3rd',
		label: '3rd',
		id: uuidv4(),
	},
	{
		value: '4th',
		label: '4th',
		id: uuidv4(),
	},
	{
		value: '5th',
		label: '5th',
		id: uuidv4(),
	},
	{
		value: '6th',
		label: '6th',
		id: uuidv4(),
	},
	{
		value: '7th',
		label: '7th',
		id: uuidv4(),
	},
	{
		value: '8th',
		label: '8th',
		id: uuidv4(),
	},
	{
		value: '9th',
		label: '9th',
		id: uuidv4(),
	},
	{
		value: '10th',
		label: '10th',
		id: uuidv4(),
	},
	{
		value: '11th',
		label: '11th',
		id: uuidv4(),
	},
	{
		value: '12th',
		label: '12th',
		id: uuidv4(),
	},
];

export const userDefinedRoles = [
	{
		value: 'admin',
		id: uuidv4(),
		label: 'admin',
	},
	{
		value: 'dept',
		id: uuidv4(),
		label: 'dept',
	},
	{
		value: 'user',
		id: uuidv4(),
		label: 'user',
	},
];
