import { StyledTextArea } from '@/Shared/StyledElements';
import styled from 'styled-components';

export const StyledTiptapMenuBar = styled.div`
	border-bottom: rgba(50, 50, 50, 0.1);
	display: flex;
	flex-wrap: wrap;
	padding-bottom: var(--spacing-2);
	position: relative;

	.editor-button {
		align-items: center;
		background: none;
		border-radius: 6px;
		border: none;
		cursor: pointer;
		display: flex;
		flex: 0 0 auto;
		height: 38px;
		justify-content: center;
		line-height: 0;
		padding: var(--spacing-4) var(--spacing-2);
		width: 38px;

		&.is-active {
			box-shadow: 0 0 0 2px var(--wine-btn-active);
		}

		&:hover,
		&:focus {
			background: var(--tab-group-color);
		}
	}
`;

export const StyledEditor = styled.div`
	position: relative;
	margin: var(--spacing-1);

	.ProseMirror {
		background-color: var(--c-white);
		border-radius: 6px;
		min-height: 150px;
		outline-color: hsl(217, 68%, 96%);
		outline-style: solid;
		outline-width: 4px;
		padding: var(--spacing-4) var(--spacing-2);

		&:focus {
			outline-color: hsl(242, 14%, 52%);
			outline-width: 2px;
		}
	}

	.ProseMirror p.is-editor-empty:first-child::before {
		color: #adb5bd;
		content: attr(data-placeholder);
		float: left;
		height: 0;
		pointer-events: none;
	}

	[contenteditable='true'] .ProseMirror-trailingBreak {
		content: '';

		&::before {
			background: linear-gradient(90deg, rgb(252, 95, 74), 50%, transparent 0px);
			content: ' ';
		}
	}

	.is-editor-empty .ProseMirror-trailingBreak {
		content: none;
	}

	.has-comment {
		background-color: var(--commented-word-color);
		cursor: pointer;
		font-style: normal;
	}

	.select-label {
		display: flex;
		flex: 0 0 auto;

		svg {
			flex: 0 0 auto;
		}
	}

	.collaboration-cursor__label {
		border-radius: 4px 4px 4px 0;
		color: #0d0d0d;
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		left: -1px;
		line-height: normal;
		padding: 0.1rem 0.3rem;
		position: absolute;
		top: -1.2em;
		user-select: none;
		white-space: nowrap;
	}

	.collaboration-cursor__caret {
		border-left: 1px solid #0d0d0d;
		border-right: 1px solid #0d0d0d;
		margin-left: -1px;
		margin-right: -1px;
		pointer-events: none;
		position: relative;
		word-break: normal;
	}

	&.tiptap {
		.tiptap-container {
			min-width: 500px;
			position: relative;
		}

		.comment-mode-toggle {
			border: none;
			color: var(--c-white);
			font-size: 12px;
			font-weight: 600;
			cursor: pointer;
			background-color: var(--comment-button);
			border-radius: 8px;
			display: flex;
			align-items: flex-start;
			padding: 5px;

			&:hover {
				background-color: var(--green-btn-active);
			}

			svg {
				margin-right: 5px;
			}
		}

		.small-btn {
			font-size: 1.4rem;
			padding: var(--spacing-1) var(--spacing-2);
		}

		.comment-section {
			article.comment {
				padding: 0.5em;
				display: flex;
				flex-direction: column;

				.comment-details {
					display: flex;
					flex-direction: column;
					margin: 0 0 8px 0;
					.date-time {
						font-size: 0.7em;
					}
				}
			}
		}

		.external-comment {
			width: 400px;
		}

		.comment-popup-section {
			display: flex;
			flex-direction: column;
			gap: 4px;

			textarea {
				border-radius: 5px;
				padding: 8px;
				resize: none;
			}

			button {
				margin-right: var(--spacing-1);
			}
		}

		[data-comment] {
			background-color: var(--commented-word-color);
		}

		.tiptap-submission [data-comment-type='general'] {
			background-color: transparent;
		}
	}

	.editor-user-identity {
		align-items: center;
		display: flex;
	}

	.editor-user-identity-dot {
		border-radius: 50%;
		height: 10px;
		width: 10px;
		margin-right: var(--spacing-1);
	}

	${StyledTextArea} {
		max-width: 500px;
		max-height: 150px;
	}

	.ProseMirror-selectednode iframe {
		border-radius: 4px;
		box-shadow: 0 0 0 4px var(--blue-300);
		display: inline-block;
	}
`;
