import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';

import React, { useMemo } from 'react';
import { GlobalAdminStyle } from '@/Shared/StyledElements';
import { ToastNotification } from '@/components/ToastNotification';
import { useUserRole } from '@/hooks/useAuth';
import CourseMap from '@/components/CourseMap/CourseMap';
import CourseEdit from '@/Pages/Courses/CourseEdit';
import { CourseEditProvider } from '@/Pages/Courses/CourseEditContext';
import Settings from '@/Pages/Settings/Settings';
import {
	Users,
	Courses,
	Submissions,
	Stats,
	Divisions,
	CourseAlignment,
	NotFound,
	EditTags,
	Disciplines,
	Privacy,
} from '../Pages/index';

const StyledPanel = styled.div`
	background-color: var(--c-white);
	border-radius: 6px;
	box-shadow: 0px 1px 0px rgba(0, 15, 25, 0.05), 0px 8px 16px rgba(0, 15, 25, 0.05);
	grid-area: panel;
	margin: var(--spacing-10) var(--spacing-4) 0 0;
	max-width: 1500px;
	overflow: hidden;
	padding: var(--spacing-3) var(--spacing-6);
	position: relative;
`;

interface PlatformRoute {
	path: string;
	element: React.ReactNode;
	children?: PlatformRoute[];
}

const Panel = () => {
	const { data: userRoleData } = useUserRole('always');

	const routes = useMemo(() => {
		if (userRoleData?.user_role === 'admin') {
			return [
				{
					path: 'courses/',
					element: <Courses />,
				},
				{
					path: 'courses/:courseId',
					element: (
						<CourseEditProvider>
							<CourseEdit />
						</CourseEditProvider>
					),
				},
				{
					path: '/users',
					element: <Users />,
				},
				{
					path: '/tags',
					element: <EditTags />,
				},
				{
					path: '/disciplines',
					element: <Disciplines />,
				},
				{
					path: '/divisions',
					element: <Divisions />,
				},
				{
					path: '/stats',
					element: <Stats />,
				},
				{
					path: '/submissions',
					element: <Submissions />,
				},
				{
					path: '/submissions/:courseId',
					element: (
						<CourseEditProvider>
							<CourseEdit />
						</CourseEditProvider>
					),
				},
				{
					path: '/course-alignment',
					element: <CourseAlignment />,
				},
				{
					path: '/course-map',
					element: <CourseMap />,
				},
				{
					path: '/privacy-policy',
					element: <Privacy />,
				},
				{
					path: '/settings',
					element: <Settings />,
				},
			];
		}
		return [
			{
				path: '/courses/',
				element: <Courses />,
			},
			{
				path: 'courses/:courseId',
				element: (
					<CourseEditProvider>
						<CourseEdit />
					</CourseEditProvider>
				),
			},
			{
				path: '/submissions',
				element: <Submissions />,
			},
			{
				path: '/submissions/:courseId',
				element: (
					<CourseEditProvider>
						<CourseEdit />
					</CourseEditProvider>
				),
			},
			{
				path: '/privacy-policy',
				exact: true,
				element: <Privacy />,
			},
			{
				path: '/course-alignment',
				element: <CourseAlignment />,
			},
		];
	}, [userRoleData?.user_role]) as PlatformRoute[];

	if (userRoleData?.user_role === undefined) {
		return null;
	}

	return (
		<>
			<GlobalAdminStyle />
			{/* <GlobalDashboardStyle /> */}
			<StyledPanel id="panel" className="panel">
				<Routes>
					{routes.map((route) => (
						<Route key={route.path} path={route.path} element={route.element} />
					))}
					<Route path="*" element={<NotFound />} />
				</Routes>
				<ToastNotification />
			</StyledPanel>
		</>
	);
};

export default Panel;
