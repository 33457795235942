import styled from 'styled-components';
import { StyledPrimaryButton } from '@/Shared/StyledElements';
import FadeIn from '@/components/animations/FadeIn';

export const StyledFade = styled(FadeIn as any)`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	width: 100%;
	align-items: flex-start;

	> ${StyledPrimaryButton} {
		margin-bottom: var(--spacing-4);
	}
`;

export const StyledCourseContainer = styled.div`
	display: flex;
	height: 100%;
	overflow: hidden;
	width: 100%;

	.course-content {
		display: grid;
		flex-basis: 100%;
		flex-grow: 0;
		grid-template-rows: auto auto 1fr;
		margin-right: var(--spacing-4);
	}

	.comment-panel {
		flex-basis: 275px;
		flex-shrink: 0;
		overflow: auto;
		padding: var(--spacing-4) var(--spacing-2);
	}
`;

export const StyledFooterBody = styled.div`
	border-top: 1px solid rgb(50 50 50 / 10%);
	display: flex;
	margin-top: auto;
	padding-top: var(--spacing-4);
	position: relative;
	text-align: right;
	width: 100%;

	> button {
		align-self: flex-end;
	}
	.actions-container {
		align-items: flex-end;
		display: flex;
		button {
			margin-right: var(--spacing-4);
		}
	}
	.draft-btn {
		margin-left: auto;
		margin-right: var(--spacing-4);
	}

	.reject-btn,
	.saving-btn {
		margin-left: auto;
	}

	.reject-btn {
		margin-right: var(--spacing-4);
	}
	.approve-all-btn {
		margin-left: var(--spacing-4);
	}
`;
