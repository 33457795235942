import styled from 'styled-components';

export const StyledSvg = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;

	svg {
		margin-bottom: var(--spacing-8);
		max-width: 400px;
		width: 90%;
	}

	p {
		max-width: 500px;
	}
`;
