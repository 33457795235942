import styled from 'styled-components';
import { media } from '@/Shared/theme';

export const StyledWrapper = styled.div`
	align-items: baseline;
	bottom: 0;
	display: flex;
	max-width: calc(100% - 120px);
	padding: var(--spacing-4);
	position: fixed;
	width: 100%;

	${media.smallScreen} {
		left: 0;
		max-width: 350px;
		padding: 0;
		position: static;
		transform: none;
	}

	.select-wrapper {
		width: 200px;

		button {
			&:hover,
			&:focus {
				background-color: var(--org-color);
			}
		}
	}

	.filter-dropdown {
		display: none;
		${media.smallScreen} {
			display: inline-block;
		}
	}

	.submit-btn {
		background-color: var(--org-color);
		border: none;
		color: var(--c-color);
		padding: var(--spacing-2);
		display: flex;
		border-radius: 6px;
		margin: auto;

		svg {
			width: 20px;
		}

		path {
			fill: var(--c-white);
		}
	}
`;

export const StyledAutoComplete = styled.div`
	flex-shrink: 0;
	margin-right: var(--spacing-4);
	position: relative;
	width: 100%;

	input {
		border-radius: 6px;
		border: 1px solid var(--input-border);
		padding: var(--spacing-2) var(--spacing-4);
		width: 100%;
	}

	.suggestions,
	.no-suggestions {
		background-color: var(--c-white);
		border-radius: 6px;
		border: 1px solid #e5e7e8;
		cursor: pointer;
		margin-top: 1px;
		max-height: 250px;
		overflow: auto;
		padding: 0;
		position: absolute;
		width: 100%;
		bottom: 42px;

		${media.smallScreen} {
			bottom: auto;
		}
	}

	.suggestion-active {
		background-color: var(--input-border);
	}

	.no-suggestions {
		cursor: default;
		padding: var(--spacing-2) var(--spacing-4);
		position: relative;

		&:focus,
		&:hover {
			background-color: var(--neutral-50);
		}
	}

	li {
		color: var(--label-text);
		padding: var(--spacing-2) var(--spacing-4);

		&:focus,
		&:hover {
			background-color: var(--input-border);
		}
	}

	.clear-btn {
		border-radius: 50%;
		border: none;
		cursor: pointer;
		line-height: 0;
		padding: 5px;
		position: absolute;
		margin-left: -24px;
		top: 12px;

		&:hover,
		&:focus {
			background-color: var(--org-color);
			color: var(--neutral-50);
			outline: none;
		}
	}
`;
