import styled from 'styled-components';

const StyledMessage = styled.p<{ color: string }>`
	align-items: center;
	background-color: ${(props) => props.color};
	border-radius: 4px;
	color: var(--body-text);
	display: grid;
	grid-template-columns: 41px 1fr;
	justify-items: center;
	margin-top: var(--spacing-4);
	padding: 8px 8px 8px 0;

	span {
		justify-self: flex-start;
	}

	svg {
		width: 20px;
	}
`;

interface BoxedIconMessageProps {
	message: string;
	messageColor: string;
	hasError: boolean;
}

export const BoxedIconMessage = ({ message, messageColor, hasError }: BoxedIconMessageProps) => {
	return (
		<StyledMessage color={messageColor}>
			{!hasError && (
				<svg viewBox="0 0 24 24" data-testid="success-icon">
					<path
						fill="var(--green-600)"
						d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
					/>
				</svg>
			)}
			{hasError && (
				<svg viewBox="0 0 24 24" data-testid="error-icon">
					<path
						fill="var(--red-500)"
						d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"
					/>
				</svg>
			)}
			<span
				dangerouslySetInnerHTML={{
					__html: message,
				}}
			/>
		</StyledMessage>
	);
};
