import styled from 'styled-components';

export const StyledActionsContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;

	button:not(:last-child) {
		margin-right: var(--spacing-4);
	}
`;
