import { useRef } from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled.span`
	display: ${(props) => (props.inline ? 'inline-block' : 'block')};
	margin-bottom: var(--spacing-4);
	position: relative;

	label {
		cursor: pointer;
		user-select: none;
	}

	label * {
		cursor: pointer;
		user-select: none;
	}

	input[type='checkbox'] {
		height: 0;
		opacity: 0;
		position: absolute;
		width: 0;
	}

	input[type='checkbox']:focus + label span {
		border-color: ${(props) => (props.accentColor ? props.accentColor : 'var(--checkbox-bg)')};
	}

	input[type='checkbox'] + label {
		align-items: center;
		color: var(--body-text);
		display: flex;
		position: relative;
	}

	input[type='checkbox'] + label > span {
		align-items: center;
		background: transparent;
		border-radius: 3px;
		border: 2px solid #9e9e9e;
		cursor: pointer;
		display: flex;
		height: 20px;
		justify-content: center;
		margin-right: var(--spacing-4);
		width: 20px;
	}

	input[type='checkbox'] + label:hover > span,
	input[type='checkbox']:focus + label > span {
		background: rgba(255, 255, 255, 0.1);
	}

	input[type='checkbox']:checked + label > span {
		background-color: ${(props) =>
			props.accentColor ? props.accentColor : 'var(--checkbox-bg)'};
		border: 4px solid
			${(props) => (props.accentColor ? props.accentColor : 'var(--checkbox-bg)')};
	}

	input[type='checkbox']:disabled + label > span,
	input[type='checkbox']:disabled + label {
		opacity: 0.5;
		cursor: not-allowed;
	}

	input[type='checkbox']:disabled + label * {
		cursor: not-allowed;
	}

	input[type='checkbox']:focus-visible + label > span {
		border-color: var(--neutral-50);
		border-style: double;
		outline: 3px solid var(--checkbox-bg);
	}

	svg {
		transition: transform 0.3s;
		transform: scale(0);
	}

	input[type='checkbox']:checked + label > span svg {
		transform: scale(1.2);
	}

	path {
		stroke-width: 3px;
	}
`;

const Checkbox = ({ ...props }) => {
	const {
		dataId,
		accentColor,
		children,
		isChecked,
		passedEvent,
		inline = true,
		className = '',
		disabled = false,
	} = props;
	const checkboxRef = useRef(null);

	const triggerClickOnEnter = (e) => {
		if (e.key === 'Enter') {
			checkboxRef.current.click();
		}
	};

	const setDefaultChecked = () => {
		if (typeof isChecked === 'object') {
			return isChecked.includes(dataId);
		}
		if (typeof isChecked === 'boolean') {
			return isChecked;
		}
		return false;
	};

	return (
		<StyledCheckbox
			className={className}
			inline={inline}
			accentColor={accentColor}
			onChange={passedEvent}>
			<input
				id={dataId}
				defaultChecked={setDefaultChecked()}
				type="checkbox"
				disabled={disabled}
				onKeyUp={(e) => triggerClickOnEnter(e)}
				ref={checkboxRef}
			/>
			<label htmlFor={dataId}>
				<span>
					<svg
						width="24"
						version="1.1"
						focusable="false"
						viewBox="0 0 24 24"
						xmlSpace="preserve">
						<path fill="none" stroke="white" d="M4.1,12.7 9,17.6 20.3,6.3" />
					</svg>
				</span>
				{children}
			</label>
		</StyledCheckbox>
	);
};

export default Checkbox;
