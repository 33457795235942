import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const dash = (color: string) => keyframes`
	from {
		fill: ${color};
		opacity: 0.3;
		stroke-dashoffset: 350;
	}
	to {
		fill: ${color};
		opacity: 0.9;
		stroke-dashoffset: 0;
	}
`;

const StyledLoader = styled.div<{ color: string; ringColor: string; size: string }>`
	align-items: center;
	color: ${(props) => props.ringColor};
	display: flex;
	flex-direction: column;
	font-size: 2rem;
	height: 100%;
	justify-content: center;
	position: relative;

	svg {
		max-width: 70px;
	}

	.svg-container {
		align-items: center;
		border: 4px solid;
		border-color: ${(props) => props.ringColor};
		border-radius: 50%;
		display: flex;
		flex-direction: column;
		height: ${(props) => (props.size === 'small' ? '200px' : '300px')};
		justify-content: center;
		padding: var(--spacing-8);
		width: ${(props) => (props.size === 'small' ? '200px' : '300px')};
	}

	path {
		animation: ${(props) => dash(props.color)} 3s linear alternate infinite;
		fill: transparent;
		stroke-dasharray: 350;
		stroke-dashoffset: 10;
		stroke-width: 0.5px;
		stroke: var(--c-white);
	}
`;

interface LoaderProps {
	color?: string;
	ringColor?: string;
	size?: 'small' | 'large';
}

const Loader = ({
	color = 'var(--elevate-secondary)',
	ringColor = 'var(--elevate-primary)',
	size = 'large',
}: LoaderProps) => {
	const [showSpinner, setShowSpinner] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => setShowSpinner(true), 650);

		return () => clearTimeout(timer);
	});

	if (!showSpinner) return null;
	return (
		<StyledLoader className="svg-loader" size={size} color={color} ringColor={ringColor}>
			<div className="svg-container">
				<svg viewBox="0 0 50 165" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M22 3c14.91 0 27 11.9 27 26.577v60.147c0 12.901-4.654 17.41-18.05 30.391-1.845 1.787-3.854 3.734-6.04 5.885V33.388l-.097-.482c-.069-.343-.216-.517-.38-.709a3.25 3.25 0 01-.202-.256c-.579-.835-2.231-.964-2.231-.964V3z" />
					<path d="M19.227 133.585v6.261l-.677-.581c-6.846-6.481-15.777-14.862-15.866-14.947a2.102 2.102 0 01-.015-3.09c.898-.858 2.358-.863 3.265-.014.083.077 6.735 6.17 13.293 12.371z" />
					<path d="M19.227 159.599c0 1.326 1.285 2.401 2.871 2.401s2.871-1.075 2.871-2.401v-8.195c1.017-1.014 1.974-1.97 2.91-2.906l.007-.006c1.925-1.925 3.763-3.761 5.85-5.828a2.767 2.767 0 00.01-3.925 2.79 2.79 0 00-3.94-.011c-1.244 1.232-1.813 1.838-2.4 2.463a87.26 87.26 0 01-2.437 2.508v-2.279h.058a7494.793 7494.793 0 0118.788-18.627l.002-.001.358-.354a2.767 2.767 0 00.017-3.925 2.792 2.792 0 00-3.94-.017c-.1.097-7.368 7.215-15.283 15.089V33.546C24.97 32.22 23.684 31 22.1 31c-1.587 0-2.872 1.22-2.872 2.546v108.226c-1.017-.956-1.579-1.522-2.127-2.074-.559-.562-1.103-1.111-2.102-2.044a2.095 2.095 0 00-3.023.143 2.247 2.247 0 00.146 3.111c1.264 1.184 2.433 2.396 3.605 3.611 1.135 1.177 2.273 2.356 3.501 3.514v11.566z" />
					<path d="M19.136 125V33.518c0-1.328 1.283-2.55 2.864-2.55V3c-5.273 0-9.546 6.45-9.546 14.404v16.661c-.614.46-1.253.978-1.947 1.554C4.782 40.362 1 48.551 1 57.86v31.357c0 13.091 3.251 17.155 13.932 30.506l.002.001c1.29 1.613 2.688 3.361 4.202 5.277z" />
				</svg>
				<div>Loading...</div>
			</div>
		</StyledLoader>
	);
};

export default Loader;
