import styled from 'styled-components';
import { StyledButton } from '@/Shared/StyledElements';
import { media } from '@/Shared/theme';

export const StyledCourseCard = styled.div<{ maxHeight: number }>`
	background-color: #ededed;
	border-radius: 6px;
	border: 4px solid #ededed;
	box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
	flex-shrink: 0;
	margin-bottom: var(--spacing-4);
	margin-right: var(--spacing-4);
	max-height: ${(props) => `${props.maxHeight}px`};
	overflow: hidden;
	position: relative;
	width: 350px;

	${media.smallScreen} {
		overflow: auto;
		width: 400px;
	}

	&:after {
		background: linear-gradient(0deg, rgb(223, 221, 216), rgba(255, 255, 255, 0));
		bottom: 0;
		content: '';
		height: 50px;
		left: 0;
		right: 0;
		position: absolute;
		width: 100%;
	}

	&.open-card {
		max-height: 9000px;
	}

	&.last-collapsed {
		background-color: #c3c1bd;
	}

	${media.smallScreen} {
		overflow: hidden;
	}

	&.selected-in-search {
		border: 4px solid var(--org-color);
	}

	&:empty {
		padding: 0;
	}

	p:empty {
		margin-bottom: var(--spacing-6);
	}

	&.spacer {
		background: var(--neutral-50);
		border: 1px dashed rgba(0, 0, 0, 0.3);

		* {
			display: none;
		}
	}

	.edit-icon {
		display: none;

		${media.smallScreen} {
			display: block;
		}
	}

	.content {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding-right: var(--spacing-4);
		padding: var(--spacing-4);
		width: 100%;
		position: relative;
	}

	time {
		color: var(--body-text);
		font-size: 1.2rem;
	}

	.media-link {
		color: var(--org-color);
		display: inline-flex;

		svg {
			align-self: center;
		}
	}

	${StyledButton} {
		align-self: flex-start;
		font-size: 1.3rem;
		margin-top: auto;
		padding: 4px var(--spacing-2);
	}

	.view-more-btn {
		position: absolute;
		bottom: var(--spacing-2);
		right: var(--spacing-2);
		font-family: var(--font-semibold);
		z-index: 1;
		background-color: var(--org-color);
		color: var(--neutral-50);
		border: none;
		padding: 4px 8px;
		cursor: pointer;
		border-radius: 4px;

		&:hover,
		&:focus {
			background-color: var(--org-color-alt);
			box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
				0 1px 5px 0 rgba(0, 0, 0, 0.12);
			outline: none;
		}
	}
`;

export const StyledCourseCardHeader = styled.div`
	box-shadow: 0px 2px 0px -1px rgba(50, 50, 50, 0.1);
	margin-bottom: var(--spacing-4);
	padding-bottom: var(--spacing-2);

	.spacer & {
		display: none;
	}
`;
