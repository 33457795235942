import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.button`
	background: none;
	border-radius: 6px;
	border: 1px solid transparent;
	cursor: pointer;
	line-height: 0;

	&:focus {
		border: 1px solid var(--orange-btn-active);
		outline: none;
	}
`;

type PasswordIconProps = {
	passedEvent(): void;
	isPasswordVisible: boolean;
};

const PasswordIcon = ({ passedEvent, isPasswordVisible }: PasswordIconProps) => (
	<StyledIcon onClick={passedEvent} type="button">
		<svg width="40" viewBox="0 0 25 17" xmlns="http://www.w3.org/2000/svg">
			<g fill="none" fillRule="evenodd">
				<path
					d="M4.66 13.238a.413.413 0 00.514-.646c-.33-.263-.65-.549-.951-.85L1.168 8.687l3.15-3.15A11.146 11.146 0 0115.812 2.84a.414.414 0 00.263-.783 11.968 11.968 0 00-12.34 2.896L0 8.687l3.638 3.639c.323.323.667.63 1.021.912zM20.742 4.954a12.13 12.13 0 00-1.814-1.493.413.413 0 00-.46.685 11.29 11.29 0 011.69 1.391l3.054 3.055-3.15 3.15a11.255 11.255 0 01-11.705 2.624.413.413 0 10-.28.777 12.081 12.081 0 0012.569-2.818l3.734-3.733-3.638-3.638z"
					fill="#FC604B"
					fillRule="nonzero"
				/>
				<circle stroke="#FC604B" strokeWidth=".9" cx="12.6" cy="8.6" r="5.15" />
				{!isPasswordVisible && (
					<path
						data-testid="slash"
						stroke="#FC604B"
						strokeLinecap="round"
						d="M4.52 16L19.82.61"
					/>
				)}
			</g>
		</svg>
		<span className="screen-reader-text">Toggle Password</span>
	</StyledIcon>
);

export default PasswordIcon;
