import styled from 'styled-components';
import { StyledSecondaryButton } from '@/Shared/StyledElements';

export const StyleDownloadButton = styled(StyledSecondaryButton as any)`
	align-items: center;
	display: inline-flex;
	grid-template-columns: auto 50px;
	padding: 0;
	position: relative;

	.label {
		padding: var(--spacing-2);
	}

	.icon {
		background-color: var(--body-text);
		border-radius: 0 3px 3px 0;
		line-height: 0;
		padding: 4px var(--spacing-2);
		transition: background-color 0.3s;
	}

	&:hover .icon,
	&:focus-visible .icon {
		background-color: var(--wine-btn-active);
	}
`;

export const StyledCourseHeader = styled.div`
	margin-bottom: var(--spacing-4);
	width: 100%;

	${StyledSecondaryButton} {
		margin-bottom: var(--spacing-4);
		margin-right: var(--spacing-4);
	}

	.title {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
	}

	.date {
		color: var(--body-text);
		font-family: var(--font-regular);
		font-size: 1.3rem;
		font-style: italic;
		font-weight: 100;
	}
`;
