import styled from 'styled-components';
import { media } from '@/Shared/theme';

export const StyledAccordionItem = styled.div`
	position: relative;
	box-shadow: 0px 0px 4px 0px rgb(50 50 50 / 10%);

	.edit-icon {
		display: none;

		${media.smallScreen} {
			display: block;
		}
	}

	.accordion-title {
		background-color: #e4e4e4;
		border: 4px solid #e4e4e4;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		text-align: left;
		width: 100%;

		&.selected-in-search {
			border-color: var(--org-color);
		}

		&:focus {
			outline: none;
		}

		&--active {
			background-color: #ededed;
			border-color: #ededed;
		}
	}

	.media-link {
		color: var(--org-color);
		display: inline-flex;

		svg {
			align-self: center;
		}
	}

	.accordion-title:hover {
		background-color: #cfcdc8;
		border-color: #cfcdc8;
	}

	.accordion-title {
		padding: var(--spacing-4);
	}

	.accordion-content {
		background-color: #e4e4e4;
		padding: var(--spacing-2) var(--spacing-4) var(--spacing-4);
	}

	.resources-block p {
		word-break: break-word;
	}

	p:empty {
		margin-bottom: var(--spacing-6);
	}
`;
