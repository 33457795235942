import { useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';

import styled from 'styled-components';
import { StyledColorIndicator, StyledSecondaryButton } from '@/Shared/StyledElements';

const StyledPopover = styled.div`
	left: 0;
	position: absolute;
	top: calc(100% + var(--spacing-2));
	z-index: 2;

	.cover {
		bottom: 0px;
		left: 0px;
		position: fixed;
		right: 0px;
		top: 0px;
	}
`;

const StyledColorOption = styled.div`
	align-items: center;
	display: flex;
	position: relative;

	.color-indicator {
		margin-right: var(--spacing-2);
	}

	.flexbox-fix svg {
		display: none;
	}
`;

interface ColorPickerProps {
	color: string;
	onChange: (colorObj: { hex: string; hsl: string }) => void;
}

const ColorPicker = ({ onChange, color }: ColorPickerProps) => {
	const [isPickerVisible, setIsPickerVisible] = useState(false);
	const [selectedColor, setSelectedColor] = useState(color);

	const handleColorChange = (color: ColorResult) => {
		const { h, s, l } = color.hsl;
		const hsl = `hsl(${h}, ${s * 100}%, ${l * 100}%)`;
		setSelectedColor(hsl);
		onChange({
			hsl,
			hex: color.hex,
		});
		return hsl;
	};
	const toggleColorPicker = () => {
		setIsPickerVisible(!isPickerVisible);
	};

	const handleClose = () => {
		setIsPickerVisible(false);
	};

	return (
		<StyledColorOption>
			<StyledColorIndicator color={selectedColor} className="color-indicator" />
			<StyledSecondaryButton onClick={toggleColorPicker} type="button">
				Select Color
			</StyledSecondaryButton>
			{isPickerVisible ? (
				<StyledPopover>
					{/* The <div> element has a child <button> element that allows keyboard interaction */}
					<div className="cover" onClick={handleClose} aria-hidden="true" />
					<ChromePicker disableAlpha color={selectedColor} onChange={handleColorChange} />
				</StyledPopover>
			) : null}
		</StyledColorOption>
	);
};

export default ColorPicker;
