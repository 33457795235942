/* eslint-disable import/no-extraneous-dependencies */
import { getMarkRange, Mark, mergeAttributes } from '@tiptap/react';
import { Plugin, TextSelection } from 'prosemirror-state';

interface CommentOptions {
	HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
	interface Commands<ReturnType> {
		comment: {
			/**
			 * Set a comment mark
			 */
			setComment: (comment: string) => ReturnType;
			/**
			 * Toggle a comment mark
			 */
			toggleComment: () => ReturnType;
			/**
			 * Unset a comment mark
			 */
			unsetComment: () => ReturnType;
		};
	}
}

export const Comment = Mark.create<CommentOptions>({
	name: 'comment',

	addOptions() {
		return {
			HTMLAttributes: {},
		};
	},

	addAttributes() {
		return {
			comment: {
				default: null,
				parseHTML: (el) => (el as HTMLSpanElement).getAttribute('data-comment'),
				renderHTML: (attrs) => {
					return {
						'data-comment': attrs.comment,
					};
				},
			},
			'data-comment-type': {
				default: null,
				parseHTML: (el) => (el as HTMLSpanElement).getAttribute('data-comment'),
				renderHTML: (attrs) => {
					if (JSON.parse(attrs.comment).comments[0]) {
						return {
							'data-comment-type': JSON.parse(attrs.comment).comments[0].commentType,
						};
					}
					return {
						'data-comment-type': null,
					};
				},
			},
			'data-uid': {
				default: null,
				parseHTML: (el) => (el as HTMLSpanElement).getAttribute('data-uid'),
				renderHTML: (attrs) => {
					if (attrs.comment) {
						return { 'data-uid': JSON.parse(attrs.comment).id };
					}

					return {};
				},
			},
		};
	},

	parseHTML() {
		return [
			{
				tag: 'span[data-comment]',
				getAttrs: (el) =>
					!!(el as HTMLSpanElement).getAttribute('data-comment')?.trim() && null,
			},
			{
				tag: 'span[data-comment-type]',
				getAttrs: (el) =>
					!!(el as HTMLSpanElement).getAttribute('data-comment-type')?.trim() && null,
			},
		];
	},

	renderHTML({ HTMLAttributes }) {
		return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
	},

	addCommands() {
		return {
			setComment:
				(comment: string) =>
				({ commands }) => {
					// set the uid as a data attribute on the comment mark
					const uid = JSON.parse(comment).id;
					return commands.setMark('comment', { comment, 'data-uid': uid });
				},
			toggleComment:
				() =>
				({ commands }) =>
					commands.toggleMark('comment'),
			unsetComment:
				() =>
				({ commands }) =>
					commands.unsetMark('comment'),
		};
	},

	addProseMirrorPlugins() {
		return [
			new Plugin({
				props: {
					handleClick(view, pos) {
						const { schema, doc, tr } = view.state;

						const range = getMarkRange(doc.resolve(pos), schema.marks.comment);

						if (!range) return false;

						const [$start, $end] = [doc.resolve(range.from), doc.resolve(range.to)];

						view.dispatch(tr.setSelection(new TextSelection($start, $end)));

						return true;
					},
				},
			}),
		];
	},
});
