import styled from 'styled-components';
import { media } from '@/Shared/theme';

const StyledIcon = styled.button`
	align-items: center;
	align-self: center;
	background-color: var(--org-color);
	border-radius: 6px;
	border: none;
	color: var(--c-white);
	cursor: pointer;
	display: flex;
	padding: var(--spacing-2);

	.panel & {
		display: none;
	}

	&:active,
	&:focus,
	&:hover {
		background-color: var(--org-color-alt);
		border-color: var(--org-color-alt);
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
			0 1px 5px 0 rgba(0, 0, 0, 0.12);
		outline: none;
	}

	span {
		display: none;

		${media.condensed} {
			margin-right: var(--spacing-2);
			display: inline;
		}
	}
`;

type ExpandIconProps = {
	className?: string;
};

const ExpandIcon = ({ className }: ExpandIconProps) => {
	const toggleFullScreen = () => {
		const elm = document.getElementById('alignment-banner');

		if (elm?.classList.contains('is-full-screen')) {
			elm?.classList.remove('is-full-screen');
		} else {
			elm?.classList.add('is-full-screen');
		}
	};

	return (
		<StyledIcon className={className} onClick={toggleFullScreen}>
			<svg
				width="21"
				height="21"
				fill="currentColor"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 215.35 215.35"
				xmlSpace="preserve">
				<path d="M7.5 65.088a7.5 7.5 0 0 0 7.5-7.5V25.61l48.305 48.295a7.479 7.479 0 0 0 5.303 2.196c1.92 0 3.84-.732 5.304-2.197a7.498 7.498 0 0 0-.001-10.606L25.604 15.002h31.985a7.5 7.5 0 0 0 0-15H7.5a7.5 7.5 0 0 0-7.5 7.5v50.087a7.5 7.5 0 0 0 7.5 7.499zM207.85 150.262a7.5 7.5 0 0 0-7.5 7.5v31.979l-49.792-49.792a7.5 7.5 0 0 0-10.607 10.606l49.791 49.791h-31.977a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h50.086a7.5 7.5 0 0 0 7.5-7.5v-50.084a7.502 7.502 0 0 0-7.501-7.5zM64.792 139.949 15.005 189.74v-31.978c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v50.086a7.5 7.5 0 0 0 7.5 7.5h50.084a7.5 7.5 0 0 0 0-15H25.611l49.788-49.793a7.5 7.5 0 1 0-10.607-10.606zM207.85.002h-50.086a7.5 7.5 0 0 0-7.5 7.5 7.5 7.5 0 0 0 7.5 7.5h31.979l-48.298 48.301a7.5 7.5 0 0 0 5.304 12.803 7.482 7.482 0 0 0 5.304-2.197l48.298-48.301v31.98a7.5 7.5 0 0 0 7.5 7.5 7.5 7.5 0 0 0 7.5-7.5V7.502a7.501 7.501 0 0 0-7.501-7.5z" />
			</svg>
		</StyledIcon>
	);
};

export default ExpandIcon;
