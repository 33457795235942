import styled from 'styled-components';

export const StyledFilters = styled.div`
	display: grid;
	gap: var(--spacing-5);
	grid-template-columns: 1fr minmax(200px, 230px);
	grid-template-rows: auto auto;
	justify-content: flex-end;
	position: relative;

	.filter-select {
		grid-column: 2;
		grid-row: 2;
	}
`;

export const StyledSearch = styled.div`
	display: inline-block;
	grid-row: 2;
	justify-self: flex-end;
	position: relative;

	&.is-single {
		grid-column: 2;
	}

	.search-icon {
		left: 12px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	input {
		border-radius: 6px;
		border: 1px solid var(--input-border);
		padding: var(--spacing-2) var(--spacing-4) var(--spacing-2) var(--spacing-10);

		&:focus {
			border-color: var(--input-focus);
			outline: none;
		}
	}

	button {
		border-radius: 50%;
		border: none;
		cursor: pointer;
		line-height: 0;
		padding: 5px;
		position: absolute;
		right: 5px;
		top: 50%;
		transform: translateY(-50%);

		&:hover,
		&:focus {
			background-color: var(--elevate-primary);
			color: var(--neutral-50);
			outline: none;
		}
	}
`;

export const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: var(--spacing-6);

	.result-count {
		color: var(--body-text);
		grid-column: 2;
		justify-self: end;
	}
`;
