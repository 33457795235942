import React from 'react';
import { StyledH6, StyledP } from '@/Shared/Typography/typography';

interface FocusEntryProps {
	type: string;
	options: FocusSkillEntity[] | undefined;
	title: string;
}

const FocusEntry = ({ type, options, title }: FocusEntryProps) => {
	const currentType = options?.filter(({ skill }) => {
		let currentSkillType = skill.skill_type;
		if (skill.skill_type === null || skill.skill_type === '') {
			currentSkillType = 'topic';
		}
		return type === currentSkillType;
	});

	if (!options || currentType?.length === 0) {
		return null;
	}

	return (
		<>
			<StyledH6
				style={{ color: 'var(--org-color)', textTransform: 'capitalize' }}
				mb="8px"
				mt="8px">
				{title}
			</StyledH6>
			{currentType?.map(({ skill }: FocusSkillEntity) => (
				<StyledP key={skill.skill_id}>{skill.skill_text}</StyledP>
			))}
		</>
	);
};

export default FocusEntry;
