import styled from 'styled-components';
import { media } from '@/Shared/theme';

const StyledIcon = styled.div`
	align-items: center;
	color: var(--label-text);
	cursor: pointer;
	display: flex;

	svg {
		transition: color 0.15s ease-in;
	}

	span {
		display: none;
		margin-left: var(--spacing-2);
		${media.condensed} {
			display: inline;
		}
	}
`;

function TagIcon() {
	return (
		<StyledIcon>
			<svg viewBox="0 10 90 109" fill="none" width="29" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M85.35 80.88c.867 0 1.568-.7 1.568-1.564V41.794c0-1.866-.729-3.774-2.053-5.374L73.24 22.37a9.183 9.183 0 00-2.07-1.85c.047-.478.074-.96.074-1.448V1.563C71.244.7 70.542 0 69.676 0c-.865 0-1.567.7-1.567 1.563 0 0-.003 17.66-.005 17.734a7.78 7.78 0 00-1.354-.12H46.477c-1.427 0-2.826.4-4.078 1.134a1.61 1.61 0 00-.161.067L26.958 27.8a8.178 8.178 0 00-3.836 4.057l-6.318 15.983a1.562 1.562 0 001.458 2.137c.625 0 1.216-.376 1.459-.99l6.305-15.952a5.03 5.03 0 012.321-2.432l8.073-3.922-8.058 9.738c-1.206 1.458-1.844 3.317-1.844 5.375v53.989c0 .629.055 1.244.156 1.844L10.522 90.49c-3.649-1.612-5.302-5.896-3.685-9.548l.02-.047L17.073 55.57a1.562 1.562 0 00-.869-2.034 1.57 1.57 0 00-2.04.867l-10.207 25.3c-2.295 5.221.076 11.338 5.295 13.644l18.94 8.371c.023.01.047.017.07.026 1.923 2.996 5.246 4.982 9.02 4.982h38.664c5.948 0 10.972-5.011 10.972-10.944v-9.171c0-.864-.701-1.564-1.567-1.564s-1.567.7-1.567 1.564v9.172c0 4.237-3.59 7.816-7.838 7.816H37.281c-4.206 0-7.628-3.506-7.628-7.816v-53.99c0-1.342.38-2.48 1.127-3.384l11.625-14.048c1.082-1.308 2.566-2.058 4.072-2.058H66.75c.296 0 .59.03.88.086a11.786 11.786 0 01-6.694 7.518 4.916 4.916 0 00-4.218-2.393c-2.708 0-4.911 2.198-4.911 4.9 0 2.7 2.203 4.898 4.911 4.898a4.914 4.914 0 004.873-4.3 14.915 14.915 0 008.836-9.085c.137.137.27.281.395.434L82.447 38.41c.862 1.041 1.337 2.243 1.337 3.385v37.521c0 .863.701 1.563 1.567 1.563zM56.719 34.185c-.98 0-1.776-.795-1.776-1.772a1.776 1.776 0 013.553 0c0 .977-.797 1.772-1.777 1.772z"
					fill="currentColor"
				/>
			</svg>
			<span>Tags</span>
		</StyledIcon>
	);
}

export default TagIcon;
