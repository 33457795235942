import styled from 'styled-components';

const StyledIcon = styled.button`
	align-items: center;
	background-color: transparent;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	display: inline-flex;
	padding: var(--spacing-2);
	transition: background-color 0.3s;

	&:active,
	&:focus,
	&:hover {
		background-color: rgba(17, 168, 171, 0.5);
		outline: none;
	}
`;

type PencilIconProps = {
	passedEvent(): void;
};

const PencilIcon = ({ passedEvent }: PencilIconProps) => (
	<StyledIcon onClick={passedEvent}>
		<svg viewBox="-3 0 113 113" fill="none" width="25">
			<path
				d="M59.768 29.348a1.662 1.662 0 00-2.338.249l-45.635 56.52-.019.027-.034.047a1.67 1.67 0 00-.08.122l-.03.05c-.032.058-.06.117-.085.177l-.005.014a1.697 1.697 0 00-.057.175l-.014.058a1.818 1.818 0 00-.028.154l-.007.034-2.58 22.923a2.777 2.777 0 001.02 2.482 2.78 2.78 0 002.64.473l21.863-7.353.032-.013a1.569 1.569 0 00.198-.086c.056-.029.11-.059.163-.094.003-.003.007-.004.01-.006a1.64 1.64 0 00.156-.122l.041-.038c.037-.033.07-.068.104-.105l.039-.043.022-.025 8.374-10.372a1.663 1.663 0 00-2.587-2.088l-7.33 9.079-9.088-7.338-9.087-7.337 44.591-55.227a1.662 1.662 0 00-.249-2.337zM22.425 96.837l8.023 6.478-18.194 6.119 2.148-19.076 8.023 6.478zm79.304-84.767L88.577 1.452a6.56 6.56 0 00-9.215.982l-9.755 12.082-5.575 6.905a1.662 1.662 0 102.587 2.088l4.53-5.611 9.088 7.337 9.087 7.337-41.75 51.709a1.662 1.662 0 102.587 2.088l42.794-53.002 9.755-12.082a6.56 6.56 0 00-.981-9.215zm-1.605 7.126l-8.712 10.79-9.087-7.338-9.087-7.337 8.711-10.789a3.231 3.231 0 014.54-.484l13.15 10.619a3.232 3.232 0 01.485 4.54z"
				fill="#484969"
			/>
		</svg>
		<span className="screen-reader-text">Edit Icon</span>
	</StyledIcon>
);

export default PencilIcon;
