import styled from 'styled-components';
import { StyledButton, StyledCheckboxLabel } from '@/Shared/StyledElements';
import { media } from '@/Shared/theme';
import FadeIn from '@/components/animations/FadeIn';

export const StyledFadeWrapper = styled(FadeIn as any)`
	display: flex;
	flex-flow: column;
	height: 100%;
	position: relative;

	${media.smallScreen} {
		height: 100vh;
	}
`;

export const StyledCardDeck = styled.div`
	width: 100%;

	${StyledButton} {
		background-color: var(--org-color);
	}

	a:not([class]) {
		color: var(--org-color);
	}
`;

export const StyledWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const StyledListCard = styled.div`
	background-color: #e4e4e4;
	border-radius: 6px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	flex-direction: column;
	flex-shrink: 0;
	margin-bottom: var(--spacing-4);
	margin-right: var(--spacing-4);
	max-height: 70vh;
	overflow: auto;
	position: relative;
	width: 350px;

	&.open-card {
		max-height: 9000px;
	}

	${media.smallScreen} {
		max-height: none;
		width: 400px;
	}

	&:empty {
		display: none;
	}

	.content {
		position: relative;
		box-shadow: 0px 2px 0px -1px rgb(50 50 50 / 10%);
		display: flex;
		flex-direction: column;
		margin-bottom: var(--spacing-4);
		padding-bottom: var(--spacing-4);

		&:last-of-type {
			box-shadow: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		span {
			margin-right: var(--spacing-2);
		}

		p,
		span {
			color: var(--body-text) !important;
		}
	}

	${StyledButton} {
		align-self: center;
		font-size: 1.3rem;
		margin-left: auto;
		padding: 4px var(--spacing-2);
	}
`;

export const StyledContainer = styled.div<{ image: string; isLoading: boolean }>`
	background-image: ${(props) => (props.isLoading ? '' : `url(${props.image})`)};
	background-position: center;
	background-repeat: no-repeat;
	background-size: 300px;
	display: flex;
	flex-direction: column;
	margin: auto;
	min-height: 300px;
	overflow: auto hidden;
	width: 100%;

	.panel & {
		overflow: auto;
	}

	${media.smallScreen} {
		height: 100%;
		overflow: auto;
	}
`;

export const StyledLegend = styled.div`
	background-color: var(--neutral-50);
	border-radius: 6px;
	box-shadow: 0 3px 8px 0 rgb(0 0 0 / 10%);
	flex-grow: 1;
	flex-shrink: 0;
	height: 97%;
	left: 0;
	margin-bottom: var(--spacing-10);
	min-width: 300px;
	overflow: auto;
	padding: var(--spacing-4) var(--spacing-4) 0;
	position: fixed;
	top: var(--spacing-2);
	width: 50%;
	z-index: 4;

	${media.smallScreen} {
		height: 100%;
		margin-bottom: 0;
		margin-right: var(--spacing-4);
		max-width: calc(100% - 120px);
		min-width: 275px;
		position: static;
		width: 100%;
		z-index: 2;
	}

	${StyledButton} {
		background-color: var(--org-color);
	}

	${StyledCheckboxLabel} {
		color: #9e9e9e;
		margin-bottom: var(--spacing-4);
	}

	strong {
		display: block;
		font-family: var(--font-semibold);
		margin-bottom: var(--spacing-4);
	}

	.legend-checkbox {
		display: table;
	}

	.legend-item {
		align-items: center;
		display: flex;
		margin-bottom: var(--spacing-2);

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.color-indicator {
		border-radius: 50%;
		flex-shrink: 0;
		height: 25px;
		margin-right: var(--spacing-2);
		width: 25px;
	}
`;

export const StyledCourseAlignment = styled.div`
	display: grid;
	flex-grow: 1;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	min-height: 50vh;
	padding: var(--spacing-6) var(--spacing-2) var(--spacing-14);
	width: 100%;

	&.active-legend {
		grid-template-columns: 300px 1fr;
	}

	${media.smallScreen} {
		grid-template-rows: 56px auto;
		padding: var(--spacing-6) var(--spacing-2) 0 0;
		position: relative;
	}

	.panel & {
		height: 100%;
	}

	.select-wrapper {
		margin-bottom: var(--spacing-4);
	}

	.fade-wrapper {
		flex-shrink: 0;
		grid-row: 1/3;
		height: 0;
		margin-right: var(--spacing-2);
		opacity: 0;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 0;
		visibility: hidden;
		width: 0;

		${media.smallScreen} {
			grid-column: 1;
		}

		.active-legend& {
			height: auto;
			opacity: 1;
			padding: revert;
			visibility: visible;
			width: auto;
		}
	}

	.search-row {
		grid-column: 1/3;
		grid-row: 1;
		max-width: 350px;
		z-index: 10;

		${media.smallScreen} {
			grid-row: 1;
			.active-legend& {
				grid-column: 2;
			}
		}
	}

	.alignment-container {
		grid-column: 1/3;

		${media.smallScreen} {
			grid-row: 2;
			.active-legend& {
				grid-column: 2;
			}
		}
	}

	.button-group {
		bottom: var(--spacing-3);
		display: flex;
		flex-direction: column;
		position: fixed;
		right: 0;
		z-index: 10;

		${media.smallScreen} {
			bottom: auto;
			flex-direction: row;
			position: absolute;
			top: 24px;
		}

		button {
			margin-bottom: var(--spacing-2);
			margin-right: var(--spacing-4);

			${media.smallScreen} {
				margin-bottom: 0;
			}
		}
	}
`;
