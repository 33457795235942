import { useRef, KeyboardEvent } from 'react';
import styled from 'styled-components';

const StyledToggle = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;

	input[type='checkbox'] {
		height: 0;
		position: absolute;
		visibility: hidden;
		width: 0;
	}

	label {
		background: var(--toggle-switch-bg);
		border-radius: 100px;
		cursor: pointer;
		height: 24px;
		position: relative;
		text-indent: -99999px;
		width: 50px;
	}

	label:after {
		background: var(--toggle-switch-circle);
		border-radius: 90px;
		content: '';
		height: 20px;
		left: 2px;
		position: absolute;
		top: 2px;
		transition: left 0.3s, transform 0.3s, width 0.3s;
		width: 20px;
	}

	input:disabled:checked + label {
		background: silver;
	}

	input:checked + label {
		background: var(--green-500);
	}

	input:checked + label:after {
		left: calc(100% - 2px);
		transform: translateX(-100%);
	}

	label:active:after {
		width: 26px;
	}

	button {
		background: none;
		border: none;
		color: #9e9e9e;
		cursor: pointer;
		margin-left: -16px;
		padding: 0 0 0 24px;

		&:focus-visible {
			outline: none;
		}
	}
`;

interface ToggleProps {
	id?: string;
	isChecked: boolean;
	label?: string;
	className?: string;
	disabled?: boolean;
	passedEvent(): void;
}

const SwitchToggle = ({
	id = 'toggle-switch',
	isChecked,
	label,
	passedEvent,
	className,
	disabled,
}: ToggleProps) => {
	const checkboxRef = useRef<HTMLInputElement>(document.createElement('input'));

	const triggerClickOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			checkboxRef.current.click();
		}
	};

	return (
		<StyledToggle className={className}>
			<input
				disabled={disabled}
				checked={isChecked}
				id={id}
				onChange={passedEvent}
				onKeyUp={(e) => triggerClickOnEnter(e)}
				ref={checkboxRef}
				type="checkbox"
			/>
			<label htmlFor={id}>{label}</label>
			<button onClick={passedEvent} type="button">
				{label}
			</button>
		</StyledToggle>
	);
};

export default SwitchToggle;
